import {
	Box,
	Button,
	LinearProgress,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Link, generatePath, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { faCopy, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
	getTransacaoTedAction,
	loadLinkPagamentoFilter,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../components/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../components/CustomTextField/CustomTextField';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';

const columns = [
	{
		headerText: 'Banco',
		key: 'banco',
	},
	{
		headerText: 'Agência',
		key: 'agencia',
	},

	{
		headerText: 'Conta',
		key: 'conta',
	},

	{
		headerText: 'Documento Destino',
		key: 'documento',
	},

	{
		headerText: 'Valor',
		key: 'valor',
		CustomValue: (value) => <p>R$ {value}</p>,
	},
	{
		headerText: 'Descrição',
		key: 'descricao',
		CustomValue: (descricao) => {
			return (
				<Tooltip title={descricao ? descricao : 'Sem descrição'}>
					<Box>
						<FontAwesomeIcon icon={faQuestionCircle} />
					</Box>
				</Tooltip>
			);
		},
	},
];

const TedTransactions = () => {
	const token = useAuth();
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const theme = useTheme();
	const history = useHistory();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();
	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	useEffect(() => {
		dispatch(
			getTransacaoTedAction(
				token,
				page,
				debouncedLike,
				filters.order,
				filters.mostrar,
				id
			)
		);
	}, [page, debouncedLike, filters.order, filters.mostrar, id]);

	const transacaoTed = useSelector((state) => state.ted);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	/* const handleClickRow = (row) => {
		const path = generatePath('/dashboard/detalhes-link/:id/ver', {
			id: row.id,
		});
		history.push(path);
	}; */

	return (
		<Box
			display="flex"
			flexDirection="column"
			style={{ position: 'absolute' }}
		>
			{token && userData === '' ? (
				<CustomBreadcrumbs
					path1="Gerenciar Listas"
					to1="goBack"
					path2="Transferência TED"
				/>
			) : (
				<CustomBreadcrumbs path1="Transferência TED" />
			)}
			<Box
				display="flex"
				justifyContent="space-between"
				flexDirection={matches ? 'column' : null}
			>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Transferência TED
				</Typography>

				{/* {token && userData === '' ? null : (
					<Link to="novo-link-pagamento">
						<GradientButton buttonText="+Novo Link" />
					</Link>
				)} */}
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por valor, descrição, número do pedido..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			{transacaoTed.data && transacaoTed.per_page ? (
				<CustomTable
					columns={columns}
					data={transacaoTed.data}
					/* handleClickRow={handleClickRow} */
				/>
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={transacaoTed.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default TedTransactions;
