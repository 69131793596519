import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './theme/theme';
import { store } from './store/store';
import { ToastContainer } from 'react-toastify';
import Root from './pages/Root';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={{ ...theme }}>
				<Root />
				<ToastContainer autoClose={3000} />
			</MuiThemeProvider>
		</Provider>
	);
};

export default App;
