export const LOAD_CONTAS = 'LOAD_CONTAS';
export const POST_LOGIN = 'POST_LOGIN';
export const LOAD_CONTA_ID = 'LOAD_CONTA_ID';
export const POST_CONTA = 'POST_CONTA';
export const LOAD_BANCOS = 'LOAD_BANCOS';
export const POST_CONTA_BANCARIA = 'POST_CONTA_BANCARIA';
export const LOAD_CONTA_BANCARIA = 'LOAD_CONTA_BANCARIA';
export const DEL_CONTA_BANCARIA = 'DEL_CONTA_BANCARIA';
export const SET_STATE = 'SET_STATE';
export const GET_APROVAR_CONTA = 'GET_APROVAR_CONTA';
export const GET_FINALIZAR_CADASTRO_CONTA = 'GET_FINALIZAR_CADASTRO_CONTA';
export const LOAD_DOCUMENTO = 'LOAD_DOCUMENTO';
export const DELETE_DOCUMENTO = 'DELETE_DOCUMENTO';
export const POST_DOCUMENTO = 'POST_DOCUMENTO';
export const GET_ENVIAR_DOCUMENTO_IDWALL = 'GET_ENVIAR_DOCUMENTO_IDWALL';
export const LOAD_ALL_CONTAS = 'LOAD_ALL_CONTAS';
export const GET_RESUMO_CONTA_DASHBOARD = 'GET_RESUMO_CONTA_DASHBOARD';
export const GET_GRAFICO_CONTA_LINE_DASHBOARD =
	'GET_GRAFICO_CONTA_LINE_DASHBOARD';
export const GET_GRAFICO_CONTA_BAR_DASHBOARD =
	'GET_GRAFICO_CONTA_BAR_DASHBOARD';
export const GET_CONTAS_EXPORT = 'GET_CONTAS_EXPORT ';
export const POST_CAPTURA = 'POST_CAPTURA';
export const LOAD_COBRANCAS_CARTAO = 'LOAD_COBRANCAS_CARTAO';
export const POST_SPLIT = 'POST_SPLIT';
export const LOAD_BOLETOS = 'LOAD_BOLETOS';
export const LOAD_CARNE = 'LOAD_CARNE';
export const LOAD_LINK_PAGAMENTOS = 'LOAD_LINK_PAGAMENTOS';
export const LOAD_LANCAMENTOS_FUTUROS = 'LOAD_LANCAMENTOS_FUTUROS';
export const LOAD_EXTRATO = 'LOAD_EXTRATO';
export const LOAD_EXPORT_EXTRATO = 'LOAD_EXPORT_EXTRATO';
export const LOAD_ASSINATURAS = 'LOAD_ASSINATURAS';
export const LOAD_PLANOS = 'LOAD_PLANOS';
export const LOAD_HISTORICO_TRANSACAO = 'LOAD_HISTORICO_TRANSACAO';
export const LOAD_EXPORT_TRANSACAO = 'LOAD_EXPORT_TRANSACAO';
export const LOAD_PAGADORES = 'LOAD_PAGADORES';
export const DEL_PAGADOR = 'DEL_PAGADOR';
export const GET_TRANSACAO_TED = 'GET_TRANSACAO_TED';
export const UPDATE_USER_CONTA = 'UPDATE_USER_CONTA';
export const GET_TRANSACAO_PIX = 'GET_TRANSACAO_PIX';
export const GET_CHAVES_PIX = 'GET_CHAVES_PIX';
export const LOAD_TRANSACAO = 'LOAD_TRANSACAO';
export const CLEAR_TRANSACAO = 'CLEAR_TRANSACAO';
export const LOAD_HISTORICO_TRANSFERENCIA = 'LOAD_HISTORICO_TRANSFERENCIA';
export const LOAD_EXPORT_TRANSFERENCIA = 'LOAD_EXPORT_TRANSFERENCIA';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const GET_LISTA_ADMINISTRADOR = 'GET_LISTA_ADMINISTRADOR';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const GET_REENVIAR_TOKEN_USUARIO = 'GET_REENVIAR_TOKEN_USUARIO';
export const POST_PRIMEIRO_ACESSO = 'POST_PRIMEIRO_ACESSO';
export const POST_RECUPERAR_SENHA = 'POST_RECUPERAR_SENHA';
export const POST_SOLICITAR_RESET = 'POST_SOLICITAR_RESET';
export const POST_EMAIL = ' POST_EMAIL';
export const LOAD_PERFIL_TAXA = 'LOAD_PERFIL_TAXA';
export const LOAD_PERFIL_TAXA_ID = 'LOAD_PERFIL_TAXA_ID';
export const POST_PERFIL_TAXA = 'POST_PERFIL_TAXA';
export const PUT_PERFIL_TAXA = 'PUT_PERFIL_TAXA';
export const DELETE_PERFIL_TAXA = 'DELETE_PERFIL_TAXA';
export const POST_VINCULAR_PERFIL_TAXA = 'POST_VINCULAR_PERFIL_TAXA';
export const POST_USER_BLOQUEAR_DESBLOQUEAR = 'POST_USER_BLOQUEAR_DESBLOQUEAR';
export const LOAD_PERMISSAO_GERENCIAR = 'LOAD_PERMISSAO_GERENCIAR';
export const POST_PERMISSAO = 'POST_PERMISSAO';
export const DEL_PERMISSAO = 'DEL_PERMISSAO';
export const POST_AUTH_ME = 'POST_AUTH_ME';
export const LOAD_PERMISSAO = 'LOAD_PERMISSAO';
export const GET_LOGS = 'GET_LOGS';
