import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
	loadContaId,
	loadPermissao,
	loadUserData,
	postAuthMeAction,
} from '../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CurrencyFormat from 'react-currency-format';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ItaDash1 from '../../assets/ItaDash1.svg';
import ItaLogo from '../../assets/ItaLogo.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		boxShadow: '0px 0px 5px 0.7px grey',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

function CustomSideBar(props) {
	const dispatch = useDispatch();
	const { id, section } = useParams();
	const token = useAuth();
	const contaSelecionada = useSelector((state) => state.conta);
	const userData = useSelector((state) => state.userData);
	const { window } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const [permissoes, setPermissoes] = useState([]);
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);
	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		if (id && token && section !== 'taxa') {
			dispatch(loadContaId(token, id));
		}
	}, [id, token, userData]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getSideBarItemBackgroundColor = (index) =>
		index === selectedIndex ? '#ffdc00' : null;

	const getSideBarItemColor = (index) =>
		index === selectedIndex ? 'white' : '#ffdc00';
	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
	};
	const drawer = (
		<Box
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				boxShadow: '0px 0px 5px 0.7px grey',
			}}
		>
			<Box>
				<img
					src={ItaDash1}
					alt=""
					style={{ width: '100px', marginLeft: '30px' }}
				/>
			</Box>
			<Box className={classes.toolbar} />

			<List>
				<ListItem
					component={Link}
					button
					selected={selectedIndex === 0}
					onClick={(event) => handleListItemClick(event, 0)}
					to="/dashboard/home"
				>
					<ListItemIcon style={{ width: '60px' }}>
						<SettingsIcon
							fontSize="50px"
							style={{
								backgroundColor: getSideBarItemBackgroundColor(0),
								color: getSideBarItemColor(0),
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							style={
								selectedIndex === 0
									? {
											fontWeight: 'bold',
											fontFamily: 'humanist-777-black-bt',
											fontSize: '14px',
									  }
									: { fontFamily: 'humanist-777-bt', fontSize: '14px' }
							}
						>
							HOME
						</Typography>
					</ListItemText>
				</ListItem>
				<ListItem
					button
					selected={selectedIndex === 1}
					onClick={(event) => handleListItemClick(event, 1)}
					component={Link}
					to="/dashboard/lista-de-contas"
				>
					<ListItemIcon>
						<SettingsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(1),
								color: getSideBarItemColor(1),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 1
								? {
										fontWeight: 'bold',
										fontFamily: 'humanist-777-black-bt',
										fontSize: '14px',
								  }
								: {
										fontFamily: 'humanist-777-bt',
										fontSize: '14px',
								  }
						}
					>
						GERENCIAR CONTAS
					</Typography>
				</ListItem>
				<ListItem
					button
					selected={selectedIndex === 2}
					onClick={(event) => handleListItemClick(event, 2)}
					component={Link}
					to="/dashboard/lista-de-administradores"
				>
					<ListItemIcon>
						<SettingsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(2),
								color: getSideBarItemColor(2),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 2
								? {
										fontWeight: 'bold',
										fontFamily: 'humanist-777-black-bt',
										fontSize: '14px',
								  }
								: {
										fontFamily: 'humanist-777-bt',
										fontSize: '14px',
								  }
						}
					>
						GERENCIAR ADMINISTRADORES
					</Typography>
				</ListItem>
				<ListItem
					button
					selected={selectedIndex === 3}
					onClick={(event) => handleListItemClick(event, 3)}
					component={Link}
					to="/dashboard/taxas"
				>
					<ListItemIcon>
						<SettingsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(3),
								color: getSideBarItemColor(3),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 3
								? {
										fontWeight: 'bold',
										fontFamily: 'humanist-777-black-bt',
										fontSize: '14px',
								  }
								: {
										fontFamily: 'humanist-777-bt',
										fontSize: '14px',
								  }
						}
					>
						GERENCIAR TARIFAS
					</Typography>
				</ListItem>
				<ListItem
					button
					selected={selectedIndex === 4}
					onClick={(event) => handleListItemClick(event, 4)}
					component={Link}
					to="/dashboard/logs"
				>
					<ListItemIcon>
						<SettingsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(4),
								color: getSideBarItemColor(4),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 4
								? {
										fontWeight: 'bold',
										fontFamily: 'humanist-777-black-bt',
										fontSize: '14px',
								  }
								: {
										fontFamily: 'humanist-777-bt',
										fontSize: '14px',
								  }
						}
					>
						VISUALIZAR LOGS
					</Typography>
				</ListItem>
			</List>
			{id && token && section !== 'taxa' ? (
				<Box
					style={{ color: 'black' }}
					display="flex"
					flexDirection="column"
					alignContent="center"
					alignItems="center"
					marginBottom="30px"
				>
					<Typography variant="h5">Conta Selecionada: </Typography>
					<Typography style={{ wordWrap: 'break-word' }} align="center">
						{contaSelecionada.nome ? contaSelecionada.nome : null}
					</Typography>
					<Typography style={{ wordWrap: 'break-word' }} align="center">
						{contaSelecionada.razao_social
							? contaSelecionada.razao_social
							: null}
					</Typography>
					<Typography>
						{contaSelecionada.documento
							? contaSelecionada.documento
							: null}
					</Typography>
					<Typography>
						{contaSelecionada.cnpj ? contaSelecionada.cnpj : null}
					</Typography>
					<Typography>
						{contaSelecionada.saldo ? (
							<CurrencyFormat
								value={contaSelecionada.saldo.valor.replace('.', ',')}
								displayType={'text'}
								thousandSeparator={'.'}
								decimalSeparator={','}
								prefix={'R$ '}
								renderText={(value) => <div> Saldo: {value}</div>}
							/>
						) : null}
					</Typography>
				</Box>
			) : null}

			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			>
				<Box
					style={{
						marginBottom: '20px',
					}}
				>
					<img src={ItaLogo} alt="" style={{ width: '200px' }} />
				</Box>
			</Box>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<div className={classes.root}>
			<CssBaseline />

			<nav className={classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
		</div>
	);
}

CustomSideBar.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default CustomSideBar;
