import SettingsIcon from '@material-ui/icons/Settings';
import {
	Box,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ItaDash4 from '../../assets/ItaDash4.svg';

const useStyles = makeStyles((theme) => ({
	iconContainer: {
		display: 'flex',

		margin: '16px',
		padding: '12px',
		/* alignItems: 'center', */
		backgroundColor: 'white',

		color: '#35322f',

		transition: `${theme.transitions.create(
			['background-color', 'transform'],
			{
				duration: theme.transitions.duration.standard,
			}
		)}`,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.secondary.light,
			transform: 'scale(1.05)',
		},
		width: '220px',
		height: '120px',
		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
		[theme.breakpoints.down('md')]: {
			width: '120px',
			height: '130px',
			margin: '16px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100px',
			height: '110px',
			margin: '6px',
		},
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 1,
			transform: 'translateX(20%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},

	textImageContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		alignItems: 'center',

		justifyContent: 'space-between',
		fontFamily: 'humanist-777-bt',
		marginTop: '3px',
		fontSize: '0.7rem',
		fontWeight: '400',
		[theme.breakpoints.up('md')]: {
			fontSize: '1rem',
			fontWeight: '500',
		},
	},

	textContainer: {
		display: 'flex',

		fontFamily: 'humanist-777-bt',

		marginLeft: '30px',
		fontSize: '0.7rem',
		fontWeight: '400',
		[theme.breakpoints.up('md')]: {
			fontSize: '1rem',
			fontWeight: '500',
		},
	},
}));

const CustomCard = ({ icon, link, text, subtext, children, ...rest }) => {
	const classes = useStyles();
	const { section } = useParams();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box>
			<Box
				display="flex"
				onClick={
					link === null
						? () =>
								toast.warning(
									'Sem permissão para acessar essa funcionalidade'
								)
						: null
				}
				boxShadow="0px 0px 5px 0.7px grey"
				className={classes.iconContainer}
				/* component={Link}
			to={link === null ? section : link} */
				{...rest}
			>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						height: '100%',
					}}
				>
					<Typography
						variant="h6"
						className={classes.textImageContainer}
						align="center"
					>
						{text}
						<img src={ItaDash4} alt="" style={{ width: '50px' }} />
					</Typography>
					<Typography
						variant="subtitle2"
						className={classes.textContainer}
						align="center"
					>
						{children}
						{subtext}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default CustomCard;
