import React from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Login from '../pages/Login/Login';
import SwitchContents from './SwitchContents';
import ListaDeContas from '../pages/ListaDeContas/ListaDeContas';
import GerenciarListaDeContas from '../pages/GerenciarListaDeContas/GerenciarListaDeContas';
import { Typography } from '@material-ui/core';
import Cadastro from '../pages/Cadastro/Cadastro';
import Recuperar from '../pages/RecuperarSenha/RecuperarSenha'
import Solicitar from '../pages/SolicitarRecuperacao/SolicitarRecuperacao'

const Routes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/dashboard/:section"
				exact
				component={SwitchContents}
			/>
			<PrivateRoute
				path="/dashboard/:section/:id/:subsection"
				exact
				component={SwitchContents}
			/>

			<Route path="/login" exact component={Login} />
			<Route path="/cadastro" exact component={Cadastro} />
			<Route path="/reset-password/:token" exact component={Recuperar} />
			<Route path="/solictar-reset" exact component={Solicitar} />
			<PrivateRoute path="/" />
		</Switch>
	);
};

export default Routes;
