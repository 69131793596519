import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../../fonts/humanist-777-bold-bt.ttf';
import '../../fonts/humanist-777-black-bt.ttf';

const useStyles = makeStyles((theme) => ({
	customButton: {
		borderRadius: '37px',
		fontWeight: 'bold',
		fontSize: '11px',
		width: '120px',
		height: '38px',
		boxShadow: '0px 0px 5px 0.7px grey',
		fontFamily: 'humanist-777-black-bt',
	},
}));

const CustomButton = (props) => {
	const classes = useStyles();
	return (
		<Button
			{...props}
			className={classes.customButton}
			variant="contained"
			style={
				props.color === 'black'
					? { backgroundColor: '#443D38', color: 'white' }
					: props.color === 'yellow'
					? { backgroundColor: '#ffdc00', color: 'black' }
					: { backgroundColor: 'white', color: '#443D38' }
			}
		>
			{props.children}
		</Button>
	);
};

export default CustomButton;
