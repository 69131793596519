import '../../fonts/humanist-777-bold-bt.ttf';

import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	TablePagination,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	delDocumento,
	getAllContasAction,
	getAprovarContaAction,
	getFinalizarCadastroContaAction,
	getContasAction,
	getContasExportAction,
	loadDocumentos,
	getReenviarTokenUsuarioAction,
	postUserBloquearDesbloquearAction,
	postAuthMeAction,
	loadPermissao,
} from '../../actions/actions';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../components/CustomButton/CustomButton';
import CustomSideBar from '../../components/CustomSideBar/CustomSideBar';
import CustomTable from '../../components/CustomTable/CustomTable';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Pagination } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '25px',
	},
	tableContainer: {},
	pageTitle: {
		color: '#c6930a',
		fontFamily: 'humanist-777-bold-bt',
	},
}));

const ListaDeContas = () => {
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const [loading, setLoading] = useState(false);
	const token = useAuth();
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const dispatch = useDispatch();
	const [permissoes, setPermissoes] = useState([]);
	useEffect(() => {
		dispatch(
			getContasAction(
				token,
				page,

				debouncedLike,
				filters.order,
				filters.mostrar
			)
		);
	}, [page, debouncedLike, filters.order, filters.mostrar]);

	const columns = [
		{ headerText: 'E-mail', key: 'email' },
		{ headerText: 'Nome', key: 'nome' },
		{ headerText: 'Tipo', key: 'tipo' },
		{
			headerText: 'Status',
			key: 'status',
			CustomValue: (value) => {
				if (
					permissoes.includes(
						'Atendimento - Consulta de status da conta'
					) ||
					permissoes.includes('Administrador - Acesso total')
				) {
					if (value === 'pending') {
						return (
							<Typography style={{ color: 'orange' }}>
								Pendente
							</Typography>
						);
					}
					if (value === 'active') {
						return (
							<Typography style={{ color: 'blue' }}>Ativo</Typography>
						);
					}
					if (value === 'enabled') {
						return (
							<Typography style={{ color: 'blue' }}>Ativo</Typography>
						);
					}
					if (value === 'approved') {
						return (
							<Typography style={{ color: 'green' }}>
								Aprovado
							</Typography>
						);
					}
					if (value === 'denied') {
						return (
							<Typography style={{ color: 'red' }}>Negado</Typography>
						);
					}
				} else {
					return null;
				}
			},
		},
		{ headerText: 'Número do Documento', key: 'numero_documento' },
		{ headerText: 'Documento', key: 'documento' },
		{ headerText: 'Contato', key: 'celular' },
		{ headerText: '', key: 'menu' },
	];

	const listaContas = useSelector((state) => state.contas);
	const allContas = useSelector((state) => state.allContas);
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);

	useEffect(() => {
		if (listaContas.total && listaContas.total > 0) {
			dispatch(getAllContasAction(token, listaContas.total));
		}
	}, [listaContas]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	const handleExportar = async () => {
		setLoading(true);
		toast.warning('A exportação pode demorar um pouco, por favor aguarde...');
		const res = await dispatch(
			getContasExportAction(
				token,
				'',
				page,
				debouncedLike,
				filters.order,
				filters.mostrar
			)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, '', '');
		} else {
		}
	};

	const Editar = (row) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleEditarUsuario = () => {
			const path = generatePath('/dashboard/editar-conta/:id/editar', {
				id: row.row.id,
			});
			{
				permissoes.includes(
					'Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)'
				) || permissoes.includes('Administrador - Acesso total')
					? history.push(path)
					: toast.error('Permissão não concedida');
			}
		};

		/* const handleGerenciarUsuario = () => {
			const path = generatePath('/dashboard/gerenciar-contas/:id/listas', {
				id: row.row.id,
			});
			history.push(path);
		}; */

		const handleGerenciarUsuario = () => {
			const path = generatePath('/dashboard/gerenciar-contas/:id/listas', {
				id: row.row.id,
			});
			{
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) || permissoes.includes('Administrador - Acesso total')
					? history.push(path)
					: toast.error('Permissão não concedida');
			}
		};
		console.log(me);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleAprovar = async (row) => {
			if (
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) ||
				permissoes.includes('Administrador - Acesso total')
			) {
				const resAprovar = await dispatch(
					getAprovarContaAction(token, row.row.id)
				);
				if (resAprovar && resAprovar.success === true) {
					toast.success('Usuário aprovado com sucesso!');
					await dispatch(getContasAction(token));
				} else {
					toast.error('Falha ao aprovar usuário');
				}
				setAnchorEl(null);
			} else {
				toast.error('Sem permissão para acessar essa funcionalidade');
			}
		};

		const handleReenviarToken = async (row) => {
			if (
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) ||
				permissoes.includes('Administrador - Acesso total')
			) {
				if (row.row.user && row.row.user.id) {
					const resAprovar = await dispatch(
						getReenviarTokenUsuarioAction(token, row.row.user.id)
					);
					if (resAprovar) {
						toast.error('Falha ao reenviar token');
					} else {
						toast.success('Token reenviado com sucesso!');
						await dispatch(getContasAction(token));
					}
					setAnchorEl(null);
				} else {
					toast.error('Usuário negado');
					return;
				}
			} else {
				toast.error('Sem permissão para acessar essa funcionalidade');
			}
		};

		const handleBloquearDesbloquear = async (row) => {
			if (row.row.user && row.row.user.id) {
				const resAprovar = await dispatch(
					postUserBloquearDesbloquearAction(token, row.row.user.id)
				);
				if (resAprovar) {
					toast.error('Falha ao reenviar token');
				} else {
					if (row.row.user && row.row.user.bloqueado === false) {
						toast.success('Usuário bloqueado!');
						await dispatch(
							getContasAction(
								token,
								page,

								debouncedLike,
								filters.order,
								filters.mostrar
							)
						);
					} else {
						await dispatch(
							getContasAction(
								token,
								page,

								debouncedLike,
								filters.order,
								filters.mostrar
							)
						);
						toast.success('Usuário desbloqueado!');
					}
				}
				setAnchorEl(null);
			} else {
				toast.error('Usuário negado');
				return;
			}
		};

		const handleFinalizar = async (row) => {
			const resAprovar = await dispatch(
				getFinalizarCadastroContaAction(token, row.row.id)
			);
			if (resAprovar && resAprovar.success === true) {
				toast.success(
					'Solicitação de finalizar cadastro enviado com sucesso!'
				);
				await dispatch(getContasAction(token));
			} else {
				toast.error('Falha ao enviar solicitação de finalizar cadastro');
			}
			setAnchorEl(null);
		};

		return (
			<Box>
				<IconButton
					style={{ height: '15px', width: '10px' }}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<SettingsIcon
						style={{
							borderRadius: 33,
							fontSize: '35px',
							backgroundColor: '#ffdc00',
							color: 'white',
						}}
					/>
				</IconButton>
				<Menu
					onClick={() => console.log(row.row.user)}
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleEditarUsuario(row)}>
						Editar
					</MenuItem>
					<MenuItem onClick={() => handleAprovar(row)}>Aprovar</MenuItem>
					{row.row.user && row.row.user.bloqueado === false ? (
						<MenuItem onClick={() => handleBloquearDesbloquear(row)}>
							Bloquear
						</MenuItem>
					) : (
						<MenuItem onClick={() => handleBloquearDesbloquear(row)}>
							Desbloquear
						</MenuItem>
					)}
					{/* <MenuItem onClick={() => handleBloquearDesbloquear(row)}>
						{row.row.user && row.row.user.bloqueado === 'false' ? (
							<Typography>Bloquear</Typography>
						) : (
							<Typography>Desbloquear</Typography>
						)}
					</MenuItem> */}
					<MenuItem onClick={() => handleReenviarToken(row)}>
						Reenviar Token de Aprovação
					</MenuItem>
					{row.row.status === 'pending' ? (
						<MenuItem onClick={() => handleFinalizar(row)}>
							Solicitar Finalização Cadastro. DOC.
						</MenuItem>
					) : (
						''
					)}
					<MenuItem onClick={() => handleGerenciarUsuario(row)}>
						Gerenciar Conta
					</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box style={{ marginBottom: '20px' }}>
					<Typography className={classes.pageTitle}>Contas</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<CustomTextField
						placeholder="Pesquisar por nome, documento, email..."
						size="small"
						variant="filled"
						style={{
							backgroundColor: '#ebc301',
							width: '400px',
						}}
						onChange={(e) => {
							setPage(1);
							setFilters({
								...filters,
								like: e.target.value,
							});
						}}
					></CustomTextField>

					<CustomButton onClick={handleExportar}>
						<Box display="flex" alignItems="center">
							<ViewListIcon />
							Exportar
						</Box>
					</CustomButton>
				</Box>
			</Box>

			<Box className={classes.tableContainer}>
				{listaContas.data && listaContas.per_page ? (
					<CustomTable
						columns={columns ? columns : null}
						data={listaContas.data}
						Editar={Editar}
					/>
				) : (
					<Box width="60vw">
						<LinearProgress color="secondary" />
					</Box>
				)}
				<Box
					display="flex"
					alignSelf="flex-end"
					marginTop="8px"
					justifyContent="space-between"
				>
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={listaContas.last_page}
						onChange={handleChangePage}
						page={page}
					/>
					<IconButton
						style={{
							backgroundColor: 'white',
							boxShadow: '0px 0px 5px 0.7px grey',
						}}
						onClick={() => window.location.reload(false)}
					>
						<RefreshIcon></RefreshIcon>
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ListaDeContas;
