import '../../fonts/humanist-777-bold-bt.ttf';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	TablePagination,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	delAdmin,
	delDocumento,
	getAllContasAction,
	getAprovarContaAction,
	getContasAction,
	getContasExportAction,
	getListaAdministradorAction,
	getReenviarTokenUsuarioAction,
	loadDocumentos,
	postCriarAdminAction,
} from '../../actions/actions';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../components/CustomButton/CustomButton';
import CustomSideBar from '../../components/CustomSideBar/CustomSideBar';
import CustomTable from '../../components/CustomTable/CustomTable';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Pagination } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '25px',
	},
	tableContainer: {},
	pageTitle: {
		color: '#c6930a',
		fontFamily: 'humanist-777-bold-bt',
	},
}));

const columns = [
	{ headerText: 'E-mail', key: 'email' },

	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			};
			const [dia] = date.toLocaleDateString('pt-br', option).split(' ');
			return <Typography align="center">{dia}</Typography>;
		},
	},

	{ headerText: '', key: 'menu' },
];

const ListaDeAdministradores = () => {
	const [email, setEmail] = useState('');
	const [open, setOpen] = useState(false);
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const [loading, setLoading] = useState(false);
	const token = useAuth();
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			getListaAdministradorAction(
				token,
				page,

				debouncedLike,
				filters.order,
				filters.mostrar
			)
		);
	}, [page, debouncedLike, filters.order, filters.mostrar]);

	const listaAdministrador = useSelector((state) => state.listaAdministrador);

	const criarAdmin = async (e) => {
		e.preventDefault();

		const resCriarAdmin = await dispatch(postCriarAdminAction(token, email));
		if (resCriarAdmin) {
			toast.error('Falha ao enviar Token!');
		} else {
			toast.success('Token enviado com sucesso!');
		}
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = (row) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [disabled, setDisabled] = useState(false);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluirAdmin = async (item) => {
			await dispatch(delAdmin(token, row.row.id));
		};

		const handlePermissions = () => {
			const path = generatePath(
				'/dashboard/lista-de-administradores/:id/permissoes',
				{
					id: row.row.id,
				}
			);
			history.push(path);
		};

		const handleReenviarTokenUsuario = async (row) => {
			setLoading(true);
			const resReenviarToken = await dispatch(
				getReenviarTokenUsuarioAction(token, row.row.id)
			);
			if (resReenviarToken === false) {
				setDisabled(true);
				toast.success('Reenviado com sucesso');
				setLoading(false);
			} else {
				toast.error('Falha ao reenviar');
				setLoading(false);
			}
		};

		return (
			<Box>
				<IconButton
					style={{ height: '15px', width: '10px' }}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<SettingsIcon
						style={{
							borderRadius: 33,
							fontSize: '35px',
							backgroundColor: '#ffdc00',
							color: 'white',
						}}
					/>
				</IconButton>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handlePermissions(row)}>
						Permissões
					</MenuItem>
					<MenuItem onClick={() => handleExcluirAdmin(row)}>
						Excluir
					</MenuItem>

					<MenuItem onClick={() => handleReenviarTokenUsuario(row)}>
						Reenviar Token de Confirmação
					</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box style={{ marginBottom: '20px' }}>
					<Typography className={classes.pageTitle}>
						Administradores
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<CustomTextField
						placeholder="Pesquisar por nome, documento, email..."
						size="small"
						variant="filled"
						style={{
							backgroundColor: '#ebc301',
							width: '400px',
						}}
						onChange={(e) =>
							setFilters({
								...filters,
								like: e.target.value,
							})
						}
					></CustomTextField>

					<Box>
						<CustomButton
							onClick={() => {
								setOpen(true);
							}}
						>
							<Box display="flex" alignItems="center">
								Criar Administrador
							</Box>
						</CustomButton>

						<Dialog
							open={open}
							onClose={() => {
								setOpen(false);
							}}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle id="form-dialog-title">
								Criar Administrador
							</DialogTitle>
							<form onSubmit={(e) => criarAdmin(e)}>
								<DialogContent>
									<DialogContentText>
										Para criar um administrador insira um e-mail.
										Enviaremos um token logo em seguida.
									</DialogContentText>

									<TextField
										onChange={(e) => setEmail(e.target.value)}
										autoFocus
										margin="dense"
										id="name"
										label="E-mail"
										type="email"
										fullWidth
									/>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={() => {
											setOpen(false);
										}}
										color="primary"
									>
										Cancel
									</Button>
									<Button
										onClick={() => {
											setOpen(false);
										}}
										color="primary"
										type="submit"
									>
										Enviar
									</Button>
								</DialogActions>
							</form>
						</Dialog>
					</Box>
				</Box>
			</Box>

			<Box className={classes.tableContainer}>
				{listaAdministrador.data && listaAdministrador.per_page ? (
					<CustomTable
						columns={columns}
						data={listaAdministrador.data}
						Editar={Editar}
					/>
				) : (
					<Box width="60vw">
						<LinearProgress color="secondary" />
					</Box>
				)}
				<Box
					display="flex"
					alignSelf="flex-end"
					marginTop="8px"
					justifyContent="space-between"
				>
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={listaAdministrador.last_page}
						onChange={handleChangePage}
						page={page}
					/>
					<IconButton
						style={{
							backgroundColor: 'white',
							boxShadow: '0px 0px 5px 0.7px grey',
						}}
						onClick={() => window.location.reload(false)}
					>
						<RefreshIcon></RefreshIcon>
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ListaDeAdministradores;
