import * as React from 'react';

import {
	ArgumentAxis,
	BarSeries,
	Chart,
	LineSeries,
	ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Typography } from '@material-ui/core';
import {
	getGraficoContaBarDashboardAction,
	getGraficoContaLineDashboardAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { useState } from 'react';

const CustomBarChart = () => {
	const token = useAuth();
	const dispatch = useDispatch();
	const graficoBarra = useSelector((state) => state.graficoBarra);

	useEffect(() => {
		dispatch(getGraficoContaBarDashboardAction(token));
	}, []);

	return (
		<Paper style={{ boxShadow: '0px 0px 5px 1px grey' }}>
			<Chart data={graficoBarra} pallete="black" height={300}>
				<Box style={{ padding: '20px' }}>
					<Typography
						style={{
							fontFamily: 'humanist-777-black-bt',
							marginTop: '3px',
						}}
					>
						Quantidade de Status de Contas
					</Typography>
				</Box>
				<ArgumentAxis style={{ color: 'black' }} />
				<ValueAxis showGrid={false} />

				<BarSeries valueField="value" argumentField="argument" barWidth={0.1} color="#ffdc00" />
			</Chart>
		</Paper>
	);
};

export default CustomBarChart;
