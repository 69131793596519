import axios from 'axios';

export const getContas = (token, page, like, order, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/contas?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postLogin = (email, password) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/login`;
	return axios({
		method: 'post',
		url,
		data: { email, password },
	});
};

export const getContaId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const putConta = (token, conta, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: conta.nome,
			razao_social: conta.razao_social,
			celular: conta.celular,
			site: conta.site,
			endereco: {
				cep: conta.endereco.cep,
				rua: conta.endereco.rua,
				numero: conta.endereco.numero,
				complemento: conta.endereco.complemento,
				bairro: conta.endereco.bairro,
				cidade: conta.endereco.cidade,
				estado: conta.endereco.estado,
			},
		},
	});
};

export const getBancos = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/banco`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postContaBancaria = (token, conta, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria?conta_id=${conta_id}`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_id: conta.conta_id,
			banco: conta.banco,
			agencia: conta.agencia,
			tipo: conta.tipo,
			conta: conta.conta,
		},
	});
};

export const getContaBancaria = (token, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria?conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteContaBancaria = (token, id, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getAprovarConta = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}/aprovar`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getFinalizarCadastroConta = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}/finalizar`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getDocumento = (token, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/documento?conta_id=${conta_id}&mostrar=100`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteDocumento = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/documento/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postDocumentos = (token, documento, categoria, descricao) => {
	const url = `${process.env.REACT_APP_API_URL}/documento`;
	var bodyFormData = new FormData();
	bodyFormData.append('arquivo', documento);
	bodyFormData.append('categoria', categoria);
	bodyFormData.append('descricao', descricao);
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: bodyFormData,
	});
};

export const postDocumentosAdm = (
	token,
	documento,
	categoria,
	conta_id,
	descricao
) => {
	const url = `${process.env.REACT_APP_API_URL}/documento_admin`;
	var bodyFormData = new FormData();
	bodyFormData.append('conta_id', conta_id);
	bodyFormData.append('documento', documento);
	bodyFormData.append('categoria', categoria);
	bodyFormData.append('descricao', descricao);
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: bodyFormData,
	});
};

export const getEnviarDocumentoIdWall = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}/sendidwall`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getResumoContaDashboard = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-quantidade`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getGraficoContaLineDashboard = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/grafico-cadastro-line`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getGraficoContaBarDashboard = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/grafico-cadastro-bar`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getContasExport = (token, id, page, like, order, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/export/conta?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postCapturaCobranca = (token, id, valor) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao/${id}/captura`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			valor: valor,
		},
	});
};

export const getCobrancasCartaoFilters = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postCobrancaEstornar = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao/${id}/estornar`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postSplit = (token, transacao) => {
	const url = `${process.env.REACT_APP_API_URL}/split`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			cobranca_boleto_ou_cartao_id: transacao.cobranca_boleto_ou_cartao_id,
			conta_id: transacao.conta_id,
			porcentagem: transacao.porcentagem,
			valor: transacao.valor,
			responsavel_pelo_prejuizo: transacao.responsavel_pelo_prejuizo,
			usar_valor_liquido: transacao.usar_valor_liquido,
		},
	});
};

export const getBoletosFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/boleto?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getCarneFilters = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/carne?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getLinkPagamentosFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getLancamentosFuturos = (token, page, data_liberacao) => {
	const url = `${process.env.REACT_APP_API_URL}/lancamento-futuro?page=${page}&data_liberacao=${data_liberacao}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExtratoFilters = (
	token,
	page,
	id,
	day,
	order,
	mostrar,
	tipo,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/extrato?page=${page}&day=${day}&id=${id}&order=${order}&mostrar=${mostrar}&tipo=${tipo}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportExtrato = (
	token,
	page,
	id,
	day,
	order,
	mostrar,
	tipo,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/extrato?page=${page}&day=${day}&id=${id}&order=${order}&mostrar=${mostrar}&tipo=${tipo}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getAssinaturasFilters = (
	token,
	page,
	like,
	plano,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura?page=${page}&like=${like}&plano=${plano}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPlanosAll = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getHistoricoTransacaoFilters = (
	token,
	page,
	day,
	order,
	mostrar,
	status,
	like,
	payment_type,
	data_inicial,
	data_final,
	id,
	documento,
	vencimento_inicial,
	vencimento_final,
	pagamento_inicial,
	pagamento_final,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao?page=${page}&id=${id}&day=${day}&order=${order}&mostrar=${mostrar}&status=${status}&like=${like}&payment_type=${payment_type}&documento=${documento}&data_inicial=${data_inicial}&data_final=${data_final}&vencimento_inicial=${vencimento_inicial}&vencimento_final=${vencimento_final}&conta_id=${conta_id}&pagamento_inicial=${pagamento_inicial}&pagamento_final=${pagamento_final}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportHistoricoTransacao = (
	token,
	page,
	day,
	order,
	mostrar,
	status,
	like,
	payment_type,
	data_inicial,
	data_final,
	id,
	documento,
	vencimento_inicial,
	vencimento_final,
	pagamento_inicial,
	pagamento_final,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/transacao?page=${page}&id=${id}&day=${day}&order=${order}&mostrar=${mostrar}&status=${status}&like=${like}&payment_type=${payment_type}&documento=${documento}&data_inicial=${data_inicial}&data_final=${data_final}&vencimento_inicial=${vencimento_inicial}&vencimento_final=${vencimento_final}&conta_id=${conta_id}&pagamento_inicial=${pagamento_inicial}&pagamento_final=${pagamento_final}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPagadoresFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deletePagador = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador/${id}`;

	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getTransacaoTed = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/ted?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getTransacaoPix = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/pagamento-pix?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getChavesPix = (token, page, like, order, mostrar, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/dict-pix?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const putUserConta = (token, conta) => {
	const url = `${process.env.REACT_APP_API_URL}/perfil`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: conta.nome,
			razao_social: conta.razao_social,
			celular: conta.celular,
			site: conta.site,
			endereco: {
				cep: conta.endereco.cep,
				rua: conta.endereco.rua,
				numero: conta.endereco.numero,
				complemento: conta.endereco.complemento,
				bairro: conta.endereco.bairro,
				cidade: conta.endereco.cidade,
				estado: conta.endereco.estado,
			},
		},
	});
};

export const getTransacaoId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getHistoricoTransferenciaFilters = (
	token,
	page,
	like,
	valor,
	data,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/transferencia?page=${page}&like=${like}&valor=${valor}&data=${data}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getHistoricoTransferencia = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/transferencia?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportHistoricoTransferencia = (
	token,
	page,
	like,
	valor,
	data,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/transferencia?page=${page}&like=${like}&valor=${valor}&data=${data}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getUserData = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/perfil`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getListaAdministrador = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/administrador`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteAdmin = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/administrador/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getReenviarTokenUsuario = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/reenviar_token/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postFirstAcess = (user) => {
	const url = `${process.env.REACT_APP_API_URL}/administrador/criar-senha`;

	return axios({
		method: 'post',
		url,
		data: {
			email: user.email,
			token: user.token,
			password: user.password,
			password_confirmation: user.password_confirmation,
		},
	});
};

export const postResetPassword = (user) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/reset/password`;

	return axios({
		method: 'post',
		url,
		data: {
			email: user.email,
			token: user.token,
			password: user.password,
			password_confirmation: user.password_confirmation,
		},
	});
};

export const postSendReset = (user) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/reset-password`;

	return axios({
		method: 'post',
		url,
		data: {
			email: user.email,
		},
	});
};

export const postCriarAdmin = (token, email) => {
	const url = `${process.env.REACT_APP_API_URL}/administrador`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { email },
	});
};

export const getCep = (cep) => {
	const url = `https://viacep.com.br/ws/${cep}/json`;

	return axios({
		method: 'get',
		url,
	});
};

export const getPerfilTaxa = (token, like) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa?like=${like}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPerfilTaxaId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postPerfilTaxa = (
	token,
	nome,
	cash_in_payout_zoop,
	tipo_cash_in_boleto,
	cash_in_boleto,
	tipo_cash_in_ted,
	cash_in_ted,
	tipo_cash_in_pix,
	cash_in_pix,
	tipo_cash_in_p2p,
	cash_in_p2p,
	tipo_cash_out_p2p,
	cash_out_p2p,
	tipo_cash_out_ted,
	cash_out_ted,
	tipo_cash_out_pix,
	cash_out_pix
) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome,
			cash_in_payout_zoop,
			tipo_cash_in_boleto,
			cash_in_boleto,
			tipo_cash_in_ted,
			cash_in_ted,
			tipo_cash_in_pix,
			cash_in_pix,
			tipo_cash_in_p2p,
			cash_in_p2p,
			tipo_cash_out_p2p,
			cash_out_p2p,
			tipo_cash_out_ted,
			cash_out_ted,
			tipo_cash_out_pix,
			cash_out_pix,
		},
	});
};

export const putPerfilTaxa = (
	token,
	nome,
	cash_in_payout_zoop,
	tipo_cash_in_boleto,
	cash_in_boleto,
	tipo_cash_in_ted,
	cash_in_ted,
	tipo_cash_in_pix,
	cash_in_pix,
	tipo_cash_in_p2p,
	cash_in_p2p,
	tipo_cash_out_p2p,
	cash_out_p2p,
	tipo_cash_out_ted,
	cash_out_ted,
	tipo_cash_out_pix,
	cash_out_pix,
	id
) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa/${id}`;
	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome,
			cash_in_payout_zoop,
			tipo_cash_in_boleto,
			cash_in_boleto,
			tipo_cash_in_ted,
			cash_in_ted,
			tipo_cash_in_pix,
			cash_in_pix,
			tipo_cash_in_p2p,
			cash_in_p2p,
			tipo_cash_out_p2p,
			cash_out_p2p,
			tipo_cash_out_ted,
			cash_out_ted,
			tipo_cash_out_pix,
			cash_out_pix,
		},
	});
};

export const deletePerfilTaxa = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postVincularPerfilTaxa = (token, id, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/perfil-taxa/${id}/vincular`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_id,
		},
	});
};

export const postUserBloquearDesbloquear = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/user/${id}/bloquear-debloquear`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPermissao = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postPermissao = (token, id, tipoPermissao) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			tipo: tipoPermissao,
			user_id: id,
		},
	});
};

export const deletePermissao = (token, id, tipoPermissao) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			tipo: tipoPermissao,
			user_id: id,
		},
	});
};

export const postAuthMe = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/me`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {},
	});
};

export const getLogs = (token, user_id, page, like, order, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/log?user_id=${user_id}&page=${page}&like=${like}&order=${order}&mostrar=${mostrar}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
