import {
	CLEAR_TRANSACAO,
	DELETE_ADMIN,
	DELETE_DOCUMENTO,
	DELETE_PERFIL_TAXA,
	DEL_CONTA_BANCARIA,
	DEL_PAGADOR,
	GET_APROVAR_CONTA,
	GET_CHAVES_PIX,
	GET_ENVIAR_DOCUMENTO_IDWALL,
	GET_GRAFICO_CONTA_BAR_DASHBOARD,
	GET_GRAFICO_CONTA_LINE_DASHBOARD,
	GET_LISTA_ADMINISTRADOR,
	GET_LOGS,
	GET_REENVIAR_TOKEN_USUARIO,
	GET_RESUMO_CONTA_DASHBOARD,
	GET_TRANSACAO_PIX,
	GET_TRANSACAO_TED,
	LOAD_ALL_CONTAS,
	LOAD_ASSINATURAS,
	LOAD_BANCOS,
	LOAD_BOLETOS,
	LOAD_CARNE,
	LOAD_COBRANCAS_CARTAO,
	LOAD_CONTAS,
	LOAD_CONTA_BANCARIA,
	LOAD_CONTA_ID,
	LOAD_DOCUMENTO,
	LOAD_EXPORT_EXTRATO,
	LOAD_EXPORT_TRANSACAO,
	LOAD_EXPORT_TRANSFERENCIA,
	LOAD_EXTRATO,
	LOAD_HISTORICO_TRANSACAO,
	LOAD_HISTORICO_TRANSFERENCIA,
	LOAD_LANCAMENTOS_FUTUROS,
	LOAD_LINK_PAGAMENTOS,
	LOAD_PAGADORES,
	LOAD_PERFIL_TAXA,
	LOAD_PERFIL_TAXA_ID,
	LOAD_PERMISSAO,
	LOAD_PERMISSAO_GERENCIAR,
	LOAD_PLANOS,
	LOAD_TRANSACAO,
	LOAD_USER_DATA,
	POST_AUTH_ME,
	POST_CAPTURA,
	POST_CONTA,
	POST_CONTA_BANCARIA,
	POST_DOCUMENTO,
	POST_EMAIL,
	POST_LOGIN,
	POST_PERFIL_TAXA,
	POST_PRIMEIRO_ACESSO,
	POST_SPLIT,
	POST_VINCULAR_PERFIL_TAXA,
	PUT_PERFIL_TAXA,
	SET_STATE,
	UPDATE_USER_CONTA,
} from '../constants/actionsStrings';
import { applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

const INITIAL_STATE = {
	adminEmail: {},
	primeiroUsuario: {},
	listaAdministrador: {},
	exportTransferencia: {},
	historicoTransferencia: {
		data: [
			{
				origem: {},
				destino: {},
			},
		],
	},
	transacao: {},
	chavesPix: {
		data: [{}],
	},
	pix: {
		data: [{}],
	},
	userData: {
		saldo: {
			valor: '',
		},
	},
	ted: {
		data: [{}],
	},
	pagadores: {
		data: [{}],
	},
	exportTransacao: {},
	historicoTransacao: {
		data: [{}],
	},
	planosList: {
		data: [{}],
	},
	assinaturasList: {
		data: [{}],
	},
	exportExtrato: {},
	extrato: {
		data: [{}],
	},
	lancamentosFuturos: {
		data: [{}],
	},
	linkPagamentos: {
		data: [{}],
	},
	carneList: {
		data: [{}],
	},
	boletos: {
		data: [{}],
	},
	split: {},
	cobrancaCartaoList: {
		data: [{}],
	},
	cobrancaCartao: {},
	graficoLinha: [],
	graficoBarra: [],
	contadores: [],
	allContas: {},
	contas: {},
	conta: {
		id: '',
		tipo: '',
		documento: '',
		documentos: [],
		conta_id: '',
		nome: '',
		celular: '',
		data_nascimento: '',
		email: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	},
	perfilTaxas: {
		data: [],
	},
	perfilTaxaId: {},
	gerenciarPermissao: {
		permissao: [{}],
	},
	userPermissao: {
		permissao: [{}],
	},
	me: {},
	logs: {},
};

const enhancer = compose(applyMiddleware(thunk));
const state = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_STATE:
			return action.payload;

		case LOAD_CONTAS:
			return { ...state, contas: action.payload };

		case LOAD_ALL_CONTAS:
			return { ...state, allContas: action.payload };

		case POST_LOGIN:
			return { ...state };

		case LOAD_CONTA_ID:
			return { ...state, conta: action.payload };

		case POST_CONTA:
			return { ...state, conta: action.payload };

		case LOAD_BANCOS:
			return { ...state, bancos: action.payload };

		case POST_CONTA_BANCARIA:
			return { ...state, contaBancaria: action.payload };

		case LOAD_CONTA_BANCARIA:
			return { ...state, contasBancarias: action.payload };

		case DEL_CONTA_BANCARIA:
			return {
				...state,
				contasBancarias: state.contasBancarias.filter(
					(item) => item.id !== action.payload
				),
			};

		case GET_APROVAR_CONTA:
			return {
				...state,
			};

		case LOAD_DOCUMENTO:
			return { ...state, arquivoDocumento: action.payload };

		case DELETE_DOCUMENTO:
			return {
				...state,
				conta: {
					...state.conta,
					documentos: state.conta.documentos.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case POST_DOCUMENTO:
			return { ...state, documentoImagem: action.payload };

		case GET_ENVIAR_DOCUMENTO_IDWALL:
			return { ...state };

		case GET_RESUMO_CONTA_DASHBOARD:
			return { ...state, contadores: action.payload };

		case GET_GRAFICO_CONTA_LINE_DASHBOARD:
			return { ...state, graficoLinha: action.payload };

		case GET_GRAFICO_CONTA_BAR_DASHBOARD:
			return { ...state, graficoBarra: action.payload };

		case POST_CAPTURA:
			return { ...state, cobrancaCartao: action.payload };

		case LOAD_COBRANCAS_CARTAO:
			return { ...state, cobrancaCartaoList: action.payload };

		case POST_SPLIT:
			return { ...state, split: action.payload };

		case LOAD_BOLETOS:
			return { ...state, boletos: action.payload };

		case LOAD_CARNE:
			return { ...state, carneList: action.payload };

		case LOAD_LINK_PAGAMENTOS:
			return { ...state, linkPagamentos: action.payload };

		case LOAD_LANCAMENTOS_FUTUROS:
			return { ...state, lancamentosFuturos: action.payload };

		case LOAD_EXTRATO:
			return { ...state, extrato: action.payload };

		case LOAD_EXPORT_EXTRATO:
			return { ...state, exportExtrato: action.payload };

		case LOAD_ASSINATURAS:
			return { ...state, assinaturasList: action.payload };

		case LOAD_PLANOS:
			return { ...state, planosList: action.payload };

		case LOAD_HISTORICO_TRANSACAO:
			return { ...state, historicoTransacao: action.payload };

		case LOAD_EXPORT_TRANSACAO:
			return { ...state, exportTransacao: action.payload };

		case LOAD_PAGADORES:
			return { ...state, pagadores: action.payload };

		case GET_TRANSACAO_TED:
			return { ...state, ted: action.payload };

		case GET_TRANSACAO_PIX:
			return { ...state, pix: action.payload };

		case GET_CHAVES_PIX:
			return { ...state, chavesPix: action.payload };

		case UPDATE_USER_CONTA:
			return { ...state, userData: action.payload };

		case LOAD_TRANSACAO:
			return { ...state, transacao: action.payload };

		case CLEAR_TRANSACAO:
			return { ...state, transacao: {} };

		case LOAD_HISTORICO_TRANSFERENCIA:
			return { ...state, historicoTransferencia: action.payload };

		case LOAD_EXPORT_TRANSFERENCIA:
			return { ...state, exportTransferencia: action.payload };

		case LOAD_USER_DATA:
			return { ...state, userData: action.payload };

		case GET_LISTA_ADMINISTRADOR:
			return { ...state, listaAdministrador: action.payload };

		case DEL_PAGADOR:
			return {
				...state,
				pagadores: {
					...state.pagadores,
					data: state.pagadores.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case POST_PRIMEIRO_ACESSO:
			return { ...state, primeiroUsuario: action.payload };

		case POST_EMAIL:
			return { ...state };

		case DELETE_ADMIN:
			return {
				...state,
				listaAdministrador: {
					...state.listaAdministrador,
					data: state.listaAdministrador.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case GET_REENVIAR_TOKEN_USUARIO:
			return { ...state };

		case LOAD_PERFIL_TAXA:
			return { ...state, perfilTaxas: action.payload };

		case LOAD_PERFIL_TAXA_ID:
			return { ...state, perfilTaxaId: action.payload };

		case POST_PERFIL_TAXA:
			return { ...state, perfilTaxaId: action.payload };

		case PUT_PERFIL_TAXA:
			return { ...state, perfilTaxaId: action.payload };

		case DELETE_PERFIL_TAXA:
			return {
				...state,
				perfilTaxas: {
					...state.perfilTaxas,
					data: state.perfilTaxas.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case LOAD_PERMISSAO_GERENCIAR:
			return { ...state, gerenciarPermissao: action.payload };

		case LOAD_PERMISSAO:
			return { ...state, userPermissao: action.payload };

		case POST_VINCULAR_PERFIL_TAXA:
			return { ...state };

		case POST_AUTH_ME:
			return { ...state, me: action.payload };

		case GET_LOGS:
			return { ...state, logs: action.payload };

		default:
			return { ...state };
	}
};

const store = createStore(state, enhancer);

export { store };
