import { Grid, Paper, useTheme } from '@material-ui/core';
import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import ItaDash1 from '../../assets/ItaDash1.svg';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { postPrimeiroAcesso } from '../../actions/actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		backgroundColor: '#ffdc00',
		margin: '0px',
		padding: '0px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},

	rightSide: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55%',
		height: '100vh',
		backgroundColor: theme.palette.background.default,
		color: '#35322f',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100vh',
		},
	},
	leftSideText: {},
	leftSide: {
		display: 'flex',
		justifyContent: 'center',
		width: '45%',
		background: '#ffdc00',
		padding: '80px',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100vh',
			padding: '0px',
		},
	},

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		height: '600px',
		alignItems: 'center',
		padding: '40px',
		width: '60%',
		borderRadius: '27px',
		animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#c6930a',
		color: 'white',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},

	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateX(-10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
}));

const Cadastro = () => {
	const classes = useStyles();
	const [user, setUser] = useState({
		email: '',
		token: '',
		password: '',
		password_confirmation: '',
	});
	const theme = useTheme();
	const [errosUser, setErrosUser] = useState({});
	const history = useHistory();
	const [, setLoading] = useState(false);
	const dispatch = useDispatch();
	const onCadastrar = async () => {
		setLoading(true);
		let newUser = user;
		const resUser = await dispatch(postPrimeiroAcesso(newUser));
		if (resUser) {
			setErrosUser(resUser);
			setLoading(false);
		} else {
			toast.success('Cadastro efetuado com sucesso, faça login para ter acesso!');
			history.push('/login');
			setLoading(false);
		}
	};

	return (
		<>
			<Box className={classes.root}>
				<Box className={classes.leftSide}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar} />
						<Typography component="h1" variant="h5" style={{ marginBottom: '4px' }}>
							Cadastrar
						</Typography>

						<Grid container spacing={5} className={classes.form}>
							<Grid item xs={12}>
								<CustomTextField
									error={errosUser.token}
									helperText={errosUser.token ? errosUser.token.join(' ') : null}
									autoFocus
									label="Código de verificação enviado por e-mail"
									fullWidth
									required
									value={user.token}
									onChange={(e) => setUser({ ...user, token: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<CustomTextField
									error={errosUser.email}
									helperText={errosUser.email ? errosUser.email.join(' ') : null}
									type="email"
									fullWidth
									label="Digite seu email"
									name="email"
									value={user.email}
									onChange={(e) => setUser({ ...user, email: e.target.value })}
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<CustomTextField
									error={errosUser.password}
									helperText={errosUser.password ? errosUser.password.join(' ') : null}
									type="password"
									required
									fullWidth
									name="password"
									label="Digite sua senha"
									id="password"
									autoComplete="current-password"
									value={user.password}
									onChange={(e) => setUser({ ...user, password: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<CustomTextField
									error={errosUser.password_confirmation}
									helperText={
										errosUser.password_confirmation
											? errosUser.password_confirmation.join(' ')
											: null
									}
									type="password"
									required
									fullWidth
									name="password"
									label="Confirmação de senha"
									id="password"
									autoComplete="current-password"
									value={user.password_confirmation}
									onChange={(e) =>
										setUser({
											...user,
											password_confirmation: e.target.value,
										})
									}
								/>
							</Grid>
							<Button
								size="large"
								fullWidth
								variant="contained"
								className={classes.submit}
								style={{
									borderRadius: '27px',
									backgroundColor: '#ffdc00',
									fontFamily: 'humanist-777-bt',
								}}
								onClick={onCadastrar}
							>
								Cadastrar
							</Button>
						</Grid>
					</Paper>
				</Box>
				<Box className={classes.rightSide}>
					<Box>
						<img
							style={{ width: '150px', justifySelf: 'flex-start' }}
							src={ItaDash1}
							alt="Itapemirim logo"
						/>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center" marginTop="150px">
						<Typography variant="h3" align="center">
							Primero acesso?
						</Typography>
						<Typography align="center" variant="h6" style={{ fontWeight: '100' }}>
							Bem-vindo! Falta pouco para finalizar seu cadastro.
						</Typography>
						<Typography align="center" variant="h6" style={{ fontWeight: '100' }}>
							Basta inserir o código enviado via EMAIL e preencher os campos.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Cadastro;
