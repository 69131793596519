import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import InputMask from 'react-input-mask';
import { getCep } from '../../services/services';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { loadPermissao, postAuthMeAction } from '../../actions/actions';

const NewAccount = ({ conta, setConta, errosConta, disableEditar }) => {
	const dispatch = useDispatch();
	const token = useAuth();
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);
	const [pessoaJuridica, setPessoaJuridica] = useState(false);
	const handlerCep = async () => {
		try {
			const response = await getCep(conta.endereco.cep);
			setConta({
				...conta,
				endereco: {
					...conta.endereco,
					cep: response.data.cep,
					rua: response.data.logradouro,
					complemento: response.data.complemento,
					bairro: response.data.bairro,
					cidade: response.data.localidade,
					estado: response.data.uf,
				},
			});
		} catch (error) {
			toast.error('Error ao puxar dados do cep');
		}
	};
	console.log(conta);

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography align="center" variant="h4" style={{ marginTop: '12px' }}>
				Dados da conta
			</Typography>

			<Box>
				<Button
					disabled={disableEditar}
					variant="contained"
					style={{
						margin: '5px',
						borderRadius: '27px',
						backgroundColor: '#35322f',
						color: 'white',
					}}
					onClick={() => setPessoaJuridica(false)}
				>
					Pessoa Física
				</Button>
				<Button
					disabled={disableEditar}
					style={{ margin: '5px', borderRadius: '27px', color: 'white' }}
					variant="contained"
					color="secondary"
					onClick={() => setPessoaJuridica(true)}
				>
					Pessoa Jurídica
				</Button>
			</Box>
			<form>
				<Box
					width="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<Grid container spacing={3}>
						<Grid item sm={4} xs={12}>
							<InputMask
								disabled={disableEditar}
								mask={'999.999.999-99'}
								value={conta.documento}
								onChange={(e) =>
									setConta({
										...conta,
										documento: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										disabled={disableEditar}
										error={errosConta.documento}
										helperText={
											errosConta.documento
												? errosConta.documento.join(' ')
												: null
										}
										name="documento"
										fullWidth
										required
										label={'CPF'}
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta.nome}
								helperText={
									errosConta.nome ? errosConta.nome.join(' ') : null
								}
								value={conta.nome}
								onChange={(e) =>
									setConta({
										...conta,
										nome: e.target.value,
									})
								}
								fullWidth
								required
								label={'Primeiro e Segundo nome'}
							/>
						</Grid>
						{pessoaJuridica || conta.tipo === 'Pessoa Jurídica' ? (
							<>
								<Grid item sm={4} xs={12}>
									<InputMask
										disabled={disableEditar}
										mask={'99.999.999/9999-99'}
										value={conta.cnpj}
										onChange={(e) =>
											setConta({
												...conta,
												cnpj: e.target.value,
											})
										}
									>
										{() => (
											<TextField
												InputLabelProps={{ shrink: true }}
												disabled={disableEditar}
												error={errosConta.cnpj}
												helperText={
													errosConta.cnpj
														? errosConta.cnpj.join(' ')
														: null
												}
												name="CNPJ"
												fullWidth
												required
												label={'CNPJ'}
											/>
										)}
									</InputMask>
								</Grid>
								<Grid item xs={12} sm={8}>
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta.razao_social}
										helperText={
											errosConta.razao_social
												? errosConta.razao_social.join(' ')
												: null
										}
										value={conta.razao_social}
										onChange={(e) =>
											setConta({
												...conta,
												razao_social: e.target.value,
											})
										}
										fullWidth
										required
										label={'Razao Social'}
									/>
								</Grid>
							</>
						) : null}
						<Grid item sm={4} xs={12}>
							<TextField
								disabled={disableEditar}
								error={errosConta.data_nascimento}
								helperText={
									errosConta.data_nascimento
										? errosConta.data_nascimento.join(' ')
										: null
								}
								fullWidth
								InputLabelProps={{
									shrink: true,
									pattern: 'd {4}- d {2}- d {2} ',
								}}
								type="date"
								label="Data de Nascimento"
								value={conta.data_nascimento}
								onChange={(e) =>
									setConta({
										...conta,
										data_nascimento: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<InputMask
								mask="99999-999"
								maskChar=" "
								value={conta.endereco.cep}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											cep: e.target.value,
										},
									})
								}
								onBlur={handlerCep}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta['endereco.cep']}
										helperText={
											errosConta['endereco.cep']
												? errosConta['endereco.cep'].join(' ')
												: null
										}
										fullWidth
										required
										label="CEP"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.rua']}
								helperText={
									errosConta['endereco.rua']
										? errosConta['endereco.rua'].join(' ')
										: null
								}
								value={conta.endereco.rua}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											rua: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Rua"
							/>
						</Grid>
						<Grid item sm={2} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.numero']}
								helperText={
									errosConta['endereco.numero']
										? errosConta['endereco.numero'].join(' ')
										: null
								}
								value={conta.endereco.numero}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											numero: e.target.value,
										},
									})
								}
								fullWidth
								label="Número"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.bairro']}
								helperText={
									errosConta['endereco.bairro']
										? errosConta['endereco.bairro'].join(' ')
										: null
								}
								value={conta.endereco.bairro}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											bairro: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Bairro"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								value={conta.endereco.complemento}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											complemento: e.target.value,
										},
									})
								}
								fullWidth
								label="Complemento"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.cidade']}
								helperText={
									errosConta['endereco.cidade']
										? errosConta['endereco.cidade'].join(' ')
										: null
								}
								value={conta.endereco.cidade}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											cidade: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Cidade"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta['endereco.estado']}
								helperText={
									errosConta['endereco.estado']
										? errosConta['endereco.estado'].join(' ')
										: null
								}
								value={conta.endereco.estado}
								onChange={(e) =>
									setConta({
										...conta,
										endereco: {
											...conta.endereco,
											estado: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Estado"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<InputMask
								mask="(99) 99999-9999"
								value={conta.celular}
								onChange={(e) =>
									setConta({
										...conta,
										celular: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										InputLabelProps={{ shrink: true }}
										error={errosConta.celular}
										helperText={
											errosConta.celular
												? errosConta.celular.join(' ')
												: null
										}
										fullWidth
										required
										label="Celular"
										type="tel"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								InputLabelProps={{ shrink: true }}
								disabled={disableEditar}
								error={errosConta.email}
								helperText={
									errosConta.email ? errosConta.email.join(' ') : null
								}
								value={conta.email}
								onChange={(e) =>
									setConta({
										...conta,
										email: e.target.value,
									})
								}
								fullWidth
								required
								label="E-mail"
								type="email"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								error={errosConta.site}
								helperText={
									errosConta.site ? errosConta.site.join(' ') : null
								}
								value={conta.site}
								onChange={(e) =>
									setConta({
										...conta,
										site: e.target.value,
									})
								}
								fullWidth
								label="Site"
								type="site"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.nome_mae}
								onChange={(e) =>
									setConta({
										...conta,
										nome_mae: e.target.value,
									})
								}
								fullWidth
								label="Nome da Mãe"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.nome_pai}
								onChange={(e) =>
									setConta({
										...conta,
										nome_pai: e.target.value,
									})
								}
								fullWidth
								label="Nome do Pai"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.cbo}
								onChange={(e) =>
									setConta({
										...conta,
										cbo: e.target.value,
									})
								}
								fullWidth
								label="CBO"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.renda_mensal}
								onChange={(e) =>
									setConta({
										...conta,
										renda_mensal: e.target.value,
									})
								}
								fullWidth
								label="Renda Mensal"
							/>
						</Grid>
						<Grid item xs={12} sm={1}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.uf_naturalidade}
								onChange={(e) =>
									setConta({
										...conta,
										uf_naturalidade: e.target.value,
									})
								}
								fullWidth
								label="UF"
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.cidade_naturalidade}
								onChange={(e) =>
									setConta({
										...conta,
										cidade_naturalidade: e.target.value,
									})
								}
								fullWidth
								label="Cidade Natal"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.sexo}
								onChange={(e) =>
									setConta({
										...conta,
										sexo: e.target.value,
									})
								}
								fullWidth
								label="Sexo"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.estado_civil}
								onChange={(e) =>
									setConta({
										...conta,
										estado_civil: e.target.value,
									})
								}
								fullWidth
								label="Estado Civil"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.numero_documento}
								onChange={(e) =>
									setConta({
										...conta,
										numero_documento: e.target.value,
									})
								}
								fullWidth
								label="Número Documento"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.uf_documento}
								onChange={(e) =>
									setConta({
										...conta,
										uf_documento: e.target.value,
									})
								}
								fullWidth
								label="UF Documento"
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.data_emissao}
								onChange={(e) =>
									setConta({
										...conta,
										data_emissao: e.target.value,
									})
								}
								fullWidth
								label="Data de Emissão"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={conta.seller_id}
								onChange={(e) =>
									setConta({
										...conta,
										seller_id: e.target.value,
									})
								}
								fullWidth
								label="Seller/Holder"
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								InputLabelProps={{ shrink: true }}
								shrink
								disabled={disableEditar}
								value={
									conta.conta
										? permissoes.includes(
												'Atendimento - Número da conta'
										  ) ||
										  permissoes.includes(
												'Administrador - Acesso total'
										  )
											? conta.conta
											: 'Sem permissão'
										: null
								}
								onChange={(e) =>
									setConta({
										...conta,
										numero_documento: e.target.value,
									})
								}
								fullWidth
								label="Número da Conta"
							/>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Box>
	);
};

export default NewAccount;
