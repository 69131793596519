import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AccountCollections from '../../components/AccountCollections/AccountCollections';
import CustomCard from '../../components/CustomCard/CustomCard';
import '../../fonts/humanist-777-bold-bt.ttf';
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
	},
	headerContainer: {
		/* padding: '80px 400px ', */
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '25px',
	},
	pageTitle: {
		color: '#c6930a',
		fontFamily: 'humanist-777-bold-bt',
	},
	subTitleContainer: {
		margin: '15px 15px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	contentContainer: {
		marginTop: '20px',
	},
}));

const GerenciarListaDeContas = () => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<AccountCollections
				ted
				pix
				chavespix
				cartao
				boleto
				carne
				assinaturas
				link
				cobranca
				extrato
				historicoTransacoes
				lancamentosFuturos
				realizarTransferencia
				historicoTransferencia
				criarContaDigital
				pagadores
				solicitarCartao
				cartoesPre
				terminais
				todos={true}
				area="Todos"
			/>
		</Box>
	);
};

export default GerenciarListaDeContas;
