import '../../fonts/humanist-777-bold-bt.ttf';
import '../../fonts/humanist-777-bt.ttf';
import '../../fonts/microgramma-d-bold-extended.otf';

import { Box, Button, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import CustomButton from '../../components/CustomButton/CustomButton';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import ItaDash1 from '../../assets/ItaDash1.svg';
import ItaDash2 from '../../assets/ItaDash2.svg';
import ItaDash3 from '../../assets/ItaDash3.svg';
import ItaDash4 from '../../assets/ItaDash4.svg';
import { Link } from 'react-router-dom';
import { postLoginAction } from '../../actions/actions';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100vw',
		height: '100vh',
		backgroundColor: '#ffdc00',
		justifyContent: 'flex-end',
		position: 'absolute',
	},
	textfield: {
		display: 'flex',
		justifyContent: 'center',

		width: '45%',
	},
	title: {
		letterSpacing: '3px',
		fontFamily: 'microgramma-d-bold-extended',
	},
	subtitle: {
		fontFamily: 'humanist-777-bt',
	},
	forgotPassword: {
		fontFamily: 'humanist-777-bold-bt',
		color: '#ba9e00',
	},
}));

const Login = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useAuth();

	const classes = useStyles();
	const [loginData, setLoginData] = useState({
		email: '',
		password: '',
	});
	const handleLogin = async (e) => {
		e.preventDefault();

		const resLogin = await dispatch(postLoginAction(loginData.email, loginData.password));
		if (resLogin) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({
					...resLogin.data,
					login_time: new Date().getTime(),
				})
			);

			history.push('/dashboard/home');
		} else {
			toast.error('Usuário ou senha inválidos');
		}
	};

	return (
		<Box className={classes.root}>
			<Box
				style={{
					width: '100%',
					height: '15%',
					display: 'flex',
					justifyContent: 'space-between',
					marginRight: '100px',
				}}
			>
				<Box style={{ marginLeft: '200px' }}>
					<img
						src={ItaDash1}
						alt=""
						style={{
							width: '100px',
						}}
					/>
				</Box>
				<Box>
					<img
						src={ItaDash2}
						alt=""
						style={{
							//width: '500px',
							height: '550px',
						}}
					/>
				</Box>
			</Box>
			<Box
				style={{
					width: '100%',
					display: 'flex',
					height: '75%',
					marginTop: '100px',
				}}
			>
				<Box
					style={{
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						alignItems: 'flex-end',
					}}
				>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							height: '100%',
							marginRight: '100px',
						}}
					>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								alignSelf: 'flex-start',
							}}
						>
							<Typography variant="h4" aling="center" className={classes.title}>
								Bem vindo (a)!
							</Typography>

							<Typography variant="h9" align="center" className={classes.subtitle}>
								Digite seu e-mail e senha para ter acesso à plataforma
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
								width: '100%',
								marginBottom: '10%',
								zIndex: 10,
							}}
						>
							<form
								onSubmit={(e) => handleLogin(e)}
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									height: '100%',
									justifyContent: 'center',
									width: '100%',
									marginBottom: '10%',
								}}
							>
								<CustomTextField
									style={{ width: '300px', margin: '10px' }}
									onChange={(e) =>
										setLoginData({
											...loginData,
											email: e.target.value,
										})
									}
									placeholder="Email"
									size="small"
									type="email"
									variant="filled"
									id="email"
									name="email"
									autoComplete="email"
									autoFocus
									required
								/>

								<CustomTextField
									style={{ width: '300px' }}
									onChange={(e) =>
										setLoginData({
											...loginData,
											password: e.target.value,
										})
									}
									placeholder="Senha"
									size="small"
									type="password"
									variant="filled"
									margin="none"
									required
									name="password"
									id="password"
									autoComplete="current-password"
								/>

								<Box
									display="flex"
									flexDirection="column"
									alignItems="center"
									marginTop="10px"
									marginBottom="30px"
								>
									<Typography variant="h9" className={classes.forgotPassword}>
										É seu primeiro acesso?
									</Typography>
									<Button
										className={classes.forgotPassword}
										size="small"
										component={Link}
										to="/cadastro"
										style={{ height: '28px', borderRadius: '27px' }}
									>
										Cadastrar senha
									</Button>
									<Button
										className={classes.forgotPassword}
										size="small"
										component={Link}
										to="/solictar-reset"
										style={{ height: '28px', borderRadius: '27px' }}
									>
										Esqueceu sua senha?
									</Button>
								</Box>
								<CustomButton color="black" type="submit">
									Entrar
								</CustomButton>
							</form>
						</Box>
					</Box>
				</Box>
				<Box>
					<img
						src={ItaDash3}
						alt=""
						style={{
							width: '500px',
						}}
					/>
				</Box>
			</Box>
			<img
				src={ItaDash4}
				alt=""
				style={{
					width: '600px',
					//height: '200px',
					position: 'absolute',
					left: '-200px',
					bottom: '0px',
					opacity: 0.3,
				}}
			/>

			{/* <Box
				style={{
					width: '500px',
					height: '1000px',
					position: 'absolute',
					display: 'flex',
					alignItems: 'flex-end',
				}}
			>
				
			</Box> */}
		</Box>
	);
};

export default Login;
