import { Box, Typography, makeStyles } from '@material-ui/core';

import AccountStatement from '../pages/AccountStatement/AccountStatement';
import AccountSubscriptions from '../pages/AccountSubscriptions/AccountSubscriptions';
import ChavesPix from '../pages/ChavesPix/ChavesPix';
import CreditCardBillingList from '../pages/CreditCardBillingList/CreditCardBillingList';
import CustomSideBar from '../components/CustomSideBar/CustomSideBar';
import Dashboard from '../pages/Dashboard/Dashboard';
import EditFees from '../pages/EditFees/EditFees';
import EditarDadosDaConta from '../pages/EditarDadosDaConta/EditarDadosDaConta';
import FutureTransactions from '../pages/FutureTransactions/FutureTransactions';
import GerenciarListaDeContas from '../pages/GerenciarListaDeContas/GerenciarListaDeContas';
import ListaDeAdministradores from '../pages/ListaDeAdministradores/ListaDeAdministradores';
import ListaDeContas from '../pages/ListaDeContas/ListaDeContas';
import ManageFees from '../pages/ManageFees/ManageFees';
import NewAccountFees from '../pages/NewAccountFees/NewAccountFees';
import Payers from '../pages/Payers/Payers';
import PaymentBooklet from '../pages/PaymentBooklet/PaymentBooklet';
import PaymentLink from '../pages/PaymentLink/PaymentLink';
import PaymentSlipList from '../pages/PaymentSlipList/PaymentSlipList';
import PixTransactions from '../pages/PixTransactions/PixTransactions';
import React, { useEffect, useState } from 'react';
import TedTransactions from '../pages/TedTransactions/TedTransactions';
import TransactionDetails from '../pages/TransactionDetails/TransactionDetails';
import TransactionHistory from '../pages/TransactionHistory/TransactionHistory';
import TransferHistory from '../pages/TransferHistory/TransferHistory';
import { useParams } from 'react-router';
import UserPermissions from '../pages/UserPermissions/UserPermissions';
import useAuth from '../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { loadPermissao, postAuthMeAction } from '../actions/actions';
import { toast } from 'react-toastify';
import Logs from '../pages/Logs/Logs';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100vh',
		width: '100%',
		margin: '0px',
		padding: '0px',
	},
	sideBarContainer: {
		display: 'flex',
		FlexDirection: 'column',
		width: '25%',
		height: '100vh',
		margin: '0px',
	},
	contentAreaContainer: {
		backgroundColor: '#fff',
		width: '100%',
		padding: '50px 50px 10px 50px',
	},
	pageHeaderContainer: {
		position: 'fixed	',
		backgroundColor: '#ffdc00',
		width: '100%',
		height: '35%',
	},
}));

const SwitchContents = () => {
	const classes = useStyles();
	const { section, id, subsection } = useParams();
	const token = useAuth();
	const dispatch = useDispatch();
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);

	const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	let content = null;

	switch (section) {
		case 'home':
			content = <Dashboard />;
			break;

		case 'lista-de-contas':
			content = <ListaDeContas />;
			break;

		case 'editar-conta':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<EditarDadosDaConta />
						) : null;
			}

			break;

		case 'gerenciar-contas':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<GerenciarListaDeContas />
						) : null;
					switch (subsection) {
						case 'cobrancas-credito':
							content = <CreditCardBillingList />;
							break;

						case 'boleto':
							content = <PaymentSlipList />;
							break;

						case 'carne':
							content = <PaymentBooklet />;
							break;

						case 'link-pagamento':
							content = <PaymentLink />;
							break;

						/* case 'lancamentos-futuros':
							content = <FutureTransactions />;
							break; */

						case 'extrato':
							content =
								permissoes.includes(
									'Atendimento - Consulta de extrato'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<AccountStatement />
								) : null;
							break;

						case 'assinaturas':
							content = <AccountSubscriptions />;
							break;

						case 'historico-de-transacoes':
							content =
								permissoes.includes(
									'Operações - Transações e histórico de transações não concluídas'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<TransactionHistory />
								) : null;

							break;

						case 'historico-transferencia':
							content = <TransferHistory />;

							break;

						case 'detalhes-transacao':
							content = <TransactionDetails />;

							break;

						case 'pagadores':
							content = <Payers />;
							break;

						case 'transferencia-ted':
							content = <TedTransactions />;
							break;

						case 'transacoes-pix':
							content = <PixTransactions />;
							break;

						case 'chaves-pix':
							content = <ChavesPix />;
							break;

						default:
					}
			}
			break;

		case 'lista-de-administradores':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Operações - Gerencimento de administradores'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<ListaDeAdministradores />
						) : null;
					switch (subsection) {
						case 'permissoes':
							content =
								permissoes.includes(
									'Operações - Gerencimento de administradores'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<UserPermissions />
								) : null;
							break;

						default:
					}
			}
			break;

		case 'taxas':
			content = <ManageFees />;
			break;

		case 'logs':
			content =
				permissoes.includes('Operações - Visualizar Logs') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<Logs />
				) : null;
			break;

		case 'taxa':
			switch (subsection) {
				case 'editar': {
					switch (id) {
						default:
							content = <EditFees />;
							break;
					}
					break;
				}
				default:
					content = null;
			}
			break;

		case 'nova-taxa':
			content = <NewAccountFees />;
			break;

		default:
			content = null;
			break;
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.sidebarContainer}>
				<CustomSideBar />
				<Box className={classes.pageHeaderContainer}></Box>
			</Box>

			<Box className={classes.contentAreaContainer}>{content}</Box>
		</Box>
	);
};

export default SwitchContents;
