import {
	CLEAR_TRANSACAO,
	DELETE_ADMIN,
	DELETE_DOCUMENTO,
	DELETE_PERFIL_TAXA,
	DEL_CONTA_BANCARIA,
	DEL_PAGADOR,
	GET_APROVAR_CONTA,
	GET_FINALIZAR_CADASTRO_CONTA,
	GET_CHAVES_PIX,
	GET_CONTAS_EXPORT,
	GET_ENVIAR_DOCUMENTO_IDWALL,
	GET_GRAFICO_CONTA_BAR_DASHBOARD,
	GET_GRAFICO_CONTA_LINE_DASHBOARD,
	GET_LISTA_ADMINISTRADOR,
	GET_REENVIAR_TOKEN_USUARIO,
	GET_RESUMO_CONTA_DASHBOARD,
	GET_TRANSACAO_PIX,
	GET_TRANSACAO_TED,
	LOAD_ALL_CONTAS,
	LOAD_ASSINATURAS,
	LOAD_BANCOS,
	LOAD_BOLETOS,
	LOAD_CARNE,
	LOAD_COBRANCAS_CARTAO,
	LOAD_CONTAS,
	LOAD_CONTA_BANCARIA,
	LOAD_CONTA_ID,
	LOAD_DOCUMENTO,
	LOAD_EXPORT_EXTRATO,
	LOAD_EXPORT_TRANSACAO,
	LOAD_EXPORT_TRANSFERENCIA,
	LOAD_EXTRATO,
	LOAD_HISTORICO_TRANSACAO,
	LOAD_HISTORICO_TRANSFERENCIA,
	LOAD_LANCAMENTOS_FUTUROS,
	LOAD_LINK_PAGAMENTOS,
	LOAD_PAGADORES,
	LOAD_PERFIL_TAXA,
	LOAD_PERFIL_TAXA_ID,
	LOAD_PLANOS,
	LOAD_TRANSACAO,
	LOAD_USER_DATA,
	POST_CAPTURA,
	POST_CONTA,
	POST_CONTA_BANCARIA,
	POST_DOCUMENTO,
	POST_EMAIL,
	POST_LOGIN,
	POST_PERFIL_TAXA,
	POST_PRIMEIRO_ACESSO,
	POST_RECUPERAR_SENHA,
	POST_SOLICITAR_RESET,
	POST_SPLIT,
	POST_VINCULAR_PERFIL_TAXA,
	PUT_PERFIL_TAXA,
	SET_STATE,
	UPDATE_USER_CONTA,
	POST_USER_BLOQUEAR_DESBLOQUEAR,
	LOAD_PERMISSAO_GERENCIAR,
	POST_PERMISSAO,
	DEL_PERMISSAO,
	POST_AUTH_ME,
	LOAD_PERMISSAO,
	GET_LOGS,
} from '../constants/actionsStrings';
import {
	deleteAdmin,
	deleteContaBancaria,
	deleteDocumento,
	deletePagador,
	deletePerfilTaxa,
	getAprovarConta,
	getFinalizarCadastroConta,
	getAssinaturasFilters,
	getBancos,
	getBoletosFilter,
	getCarneFilters,
	getChavesPix,
	getCobrancasCartaoFilters,
	getContaBancaria,
	getContaId,
	getContas,
	getContasExport,
	getDocumento,
	getEnviarDocumentoIdWall,
	getExportExtrato,
	getExportHistoricoTransacao,
	getExportHistoricoTransferencia,
	getExtratoFilters,
	getGraficoContaBarDashboard,
	getGraficoContaLineDashboard,
	getHistoricoTransacaoFilters,
	getHistoricoTransferencia,
	getHistoricoTransferenciaFilters,
	getLancamentosFuturos,
	getLinkPagamentosFilter,
	getListaAdministrador,
	getPagadoresFilter,
	getPerfilTaxa,
	getPerfilTaxaId,
	getPlanosAll,
	getReenviarTokenUsuario,
	getResumoContaDashboard,
	getTransacaoId,
	getTransacaoPix,
	getTransacaoTed,
	getUserData,
	postCapturaCobranca,
	postCobrancaEstornar,
	postContaBancaria,
	postCriarAdmin,
	postDocumentos,
	postDocumentosAdm,
	postFirstAcess,
	postLogin,
	postPerfilTaxa,
	postResetPassword,
	postSendReset,
	postSplit,
	postVincularPerfilTaxa,
	putConta,
	putPerfilTaxa,
	putUserConta,
	postUserBloquearDesbloquear,
	getPermissao,
	postPermissao,
	deletePermissao,
	postAuthMe,
	getLogs,
} from '../services/services';

import { toast } from 'react-toastify';

export const getContasAction =
	(token, page, like, order, mostrar) => async (dispatch) => {
		try {
			const res = await getContas(token, page, like, order, mostrar);
			dispatch({
				type: LOAD_CONTAS,
				payload: res.data,
			});
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

export const getAllContasAction = (token, mostrar) => async (dispatch) => {
	try {
		const res = await getContas(token, '', '', '', mostrar);
		dispatch({
			type: LOAD_ALL_CONTAS,
			payload: res.data,
		});
		return true;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const postLoginAction = (email, password) => async (dispatch) => {
	try {
		const res = await postLogin(email, password);
		dispatch({
			type: POST_LOGIN,
			payload: res.data,
		});
		return res;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const loadContaId = (token, id) => async (dispatch) => {
	try {
		const res = await getContaId(token, id);
		dispatch({
			type: LOAD_CONTA_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const updateConta = (token, conta, id) => async (dispatch) => {
	try {
		const res = await putConta(token, conta, id);
		dispatch({
			type: POST_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const loadBancos = (token) => async (dispatch) => {
	try {
		const res = await getBancos(token);
		dispatch({
			type: LOAD_BANCOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postContaBancariaAction =
	(token, conta, conta_id) => async (dispatch) => {
		try {
			const res = await postContaBancaria(token, conta, conta_id);
			dispatch({
				type: POST_CONTA_BANCARIA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error('Erro no cadastro');
				return null;
			}
		}
	};

export const loadContaBancaria = (token, conta_id) => async (dispatch) => {
	try {
		const res = await getContaBancaria(token, conta_id);
		dispatch({
			type: LOAD_CONTA_BANCARIA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getAprovarContaAction = (token, id) => async (dispatch) => {
	try {
		const res = await getAprovarConta(token, id);
		dispatch({
			type: GET_APROVAR_CONTA,
			payload: res.data,
		});
		console.log(res.data);
		return res.data;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getFinalizarCadastroContaAction =
	(token, id) => async (dispatch) => {
		try {
			const res = await getFinalizarCadastroConta(token, id);
			dispatch({
				type: GET_FINALIZAR_CADASTRO_CONTA,
				payload: res.data,
			});
			console.log(res.data);
			return res.data;
		} catch (err) {
			console.log(err);
			return null;
		}
	};

export const delContaBancaria =
	(token, id, conta_id) => async (dispatch, getState) => {
		const state = getState();
		try {
			dispatch({
				type: DEL_CONTA_BANCARIA,
				payload: id,
			});
			await deleteContaBancaria(token, id, conta_id);
		} catch (err) {
			dispatch({
				type: SET_STATE,
				payload: state,
			});
			toast.error('Erro ao excluir conta Bancaria');
			console.error(err);
		}
	};

export const delDocumento = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DELETE_DOCUMENTO,
			payload: id,
		});
		await deleteDocumento(token, id);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao deletar documento');
	}
};

export const loadDocumentos = (token, conta_id) => async (dispatch) => {
	try {
		const res = await getDocumento(token, conta_id);
		dispatch({
			type: LOAD_DOCUMENTO,
			payload: res.data.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postDocumentoAction =
	(token, documento, categoria) => async (dispatch) => {
		const documentoObjeto = { ...documento };
		try {
			const res = await postDocumentos(
				token,
				documentoObjeto[0].file,
				categoria,
				documentoObjeto[0].file.type
			);
			dispatch({
				type: POST_DOCUMENTO,
				payload: res.data,
			});
			toast.success('Documento adicionado com sucesso');
		} catch (err) {
			console.log(err);
			toast.error('Erro ao adicionar o documento');
		}
	};

export const postDocumentoActionAdm =
	(token, documento, categoria, conta_id) => async (dispatch) => {
		const documentoObjeto = { ...documento };
		try {
			const res = await postDocumentosAdm(
				token,
				documentoObjeto[0].file,
				categoria,
				conta_id,
				documentoObjeto[0].file.type
			);
			dispatch({
				type: POST_DOCUMENTO,
				payload: res.data,
			});
			toast.success('Documento adicionado com sucesso');
		} catch (err) {
			if (err.response.status === 422) {
				console.log(err.response.data.errors);
			} else {
				console.log(err);
				toast.error('erro');
				console.log(err.response.data.errors);
			}
			console.log('erro no postdocumentoactionADM');
			console.log(err);
			toast.error('Erro ao adicionar o documento');
		}
	};

export const getEnviarDocumentoIdWallAction =
	(token, id) => async (dispatch) => {
		try {
			const res = await getEnviarDocumentoIdWall(token, id);
			dispatch({
				type: GET_ENVIAR_DOCUMENTO_IDWALL,
				payload: res.data,
			});

			console.log(res.data);
			return false;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao reenviar');
			return true;
		}
	};

export const getResumoContaDashboardAction = (token) => async (dispatch) => {
	try {
		const res = await getResumoContaDashboard(token);
		dispatch({
			type: GET_RESUMO_CONTA_DASHBOARD,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getGraficoContaLineDashboardAction =
	(token) => async (dispatch) => {
		try {
			const res = await getGraficoContaLineDashboard(token);
			dispatch({
				type: GET_GRAFICO_CONTA_LINE_DASHBOARD,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const getGraficoContaBarDashboardAction =
	(token) => async (dispatch) => {
		try {
			const res = await getGraficoContaBarDashboard(token);
			dispatch({
				type: GET_GRAFICO_CONTA_BAR_DASHBOARD,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const getContasExportAction =
	(token, id, page, like, order, mostrar) => async (dispatch) => {
		try {
			const res = await getContasExport(
				token,
				id,
				page,
				like,
				order,
				mostrar
			);
			dispatch({
				type: GET_CONTAS_EXPORT,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

export const postCapturaCobrancaAction =
	(token, id, valor) => async (dispatch) => {
		try {
			const res = await postCapturaCobranca(token, id, valor);
			dispatch({
				type: POST_CAPTURA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				console.log(err);
				toast.error('erro');
				return null;
			}
		}
	};

export const loadCobrancasCartaoFilters =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getCobrancasCartaoFilters(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_COBRANCAS_CARTAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const postCobrancaEstornarAction = (token, id) => async () => {
	try {
		const res = await postCobrancaEstornar(token, id);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const postSplitAction = (token, transacao) => async (dispatch) => {
	try {
		const res = await postSplit(token, transacao);
		dispatch({
			type: POST_SPLIT,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response && err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro');
			return err;
		}
	}
};

export const loadBoletosFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getBoletosFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_BOLETOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadCarneFilters =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getCarneFilters(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_CARNE,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadLinkPagamentoFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getLinkPagamentosFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_LINK_PAGAMENTOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadLancamentosFuturos =
	(token, page, data_liberacao) => async (dispatch) => {
		try {
			const res = await getLancamentosFuturos(token, page, data_liberacao);
			dispatch({
				type: LOAD_LANCAMENTOS_FUTUROS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadExtratoFilter =
	(token, page, id, day, order, mostrar, tipo, conta_id) =>
	async (dispatch) => {
		try {
			const res = await getExtratoFilters(
				token,
				page,
				id,
				day,
				order,
				mostrar,
				tipo,
				conta_id
			);
			dispatch({
				type: LOAD_EXTRATO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadExportExtrato =
	(token, page, id, day, order, mostrar, tipo, conta_id) =>
	async (dispatch) => {
		try {
			const res = await getExportExtrato(
				token,
				page,
				id,
				day,
				order,
				mostrar,
				tipo,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_EXTRATO,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};

export const loadAssinaturasFilters =
	(token, page, like, plano, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getAssinaturasFilters(
				token,
				page,
				like,
				plano,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_ASSINATURAS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadPlanosAll = (token) => async (dispatch) => {
	try {
		const res = await getPlanosAll(token);
		dispatch({
			type: LOAD_PLANOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadHistoricoTransacaoFilter =
	(
		token,
		page,
		day,
		order,
		mostrar,
		status,
		like,
		payment_type,
		data_inicial,
		data_final,
		id,
		documento,
		vencimento_inicial,
		vencimento_final,
		pagamento_inicial,
		pagamento_final,
		conta_id
	) =>
	async (dispatch) => {
		try {
			const res = await getHistoricoTransacaoFilters(
				token,
				page,
				day,
				order,
				mostrar,
				status,
				like,
				payment_type,
				data_inicial,
				data_final,
				id,
				documento,
				vencimento_inicial,
				vencimento_final,
				pagamento_inicial,
				pagamento_final,
				conta_id
			);
			dispatch({
				type: LOAD_HISTORICO_TRANSACAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadExportHistoricoTransacao =
	(
		token,
		page,
		day,
		order,
		mostrar,
		status,
		like,
		payment_type,
		data_inicial,
		data_final,
		id,
		documento,
		vencimento_inicial,
		vencimento_final,
		pagamento_inicial,
		pagamento_final,
		conta_id
	) =>
	async (dispatch) => {
		try {
			const res = await getExportHistoricoTransacao(
				token,
				page,
				day,
				order,
				mostrar,
				status,
				like,
				payment_type,
				data_inicial,
				data_final,
				id,
				documento,
				vencimento_inicial,
				vencimento_final,
				pagamento_inicial,
				pagamento_final,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_TRANSACAO,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};

export const loadPagadoresFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getPagadoresFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_PAGADORES,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const delPagador = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_PAGADOR,
			payload: id,
		});
		await deletePagador(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir o pagador');
	}
};

export const getTransacaoTedAction =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getTransacaoTed(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: GET_TRANSACAO_TED,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const getTransacaoPixAction =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getTransacaoPix(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: GET_TRANSACAO_PIX,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const getChavesPixAction =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getChavesPix(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: GET_CHAVES_PIX,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const updateUserConta = (token, conta) => async (dispatch) => {
	try {
		const res = await putUserConta(token, conta);
		dispatch({
			type: UPDATE_USER_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const loadTransacaoId = (token, id) => async (dispatch) => {
	try {
		const res = await getTransacaoId(token, id);
		dispatch({
			type: LOAD_TRANSACAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const clearTransacao = () => async (dispatch) => {
	dispatch({
		type: CLEAR_TRANSACAO,
	});
};

export const loadHistoricoTransferenciaFilters =
	(token, page, like, valor, data, conta_id) => async (dispatch) => {
		try {
			const res = await getHistoricoTransferenciaFilters(
				token,
				page,
				like,
				valor,
				data,
				conta_id
			);
			dispatch({
				type: LOAD_HISTORICO_TRANSFERENCIA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadHistoricoTransferencia = (token, page) => async (dispatch) => {
	try {
		const res = await getHistoricoTransferencia(token, page);
		dispatch({
			type: LOAD_HISTORICO_TRANSFERENCIA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadExportHistoricoTransferencia =
	(token, page, like, valor, data, conta_id) => async (dispatch) => {
		try {
			const res = await getExportHistoricoTransferencia(
				token,
				page,
				like,
				valor,
				data,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_TRANSFERENCIA,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};

export const loadUserData = (token) => async (dispatch) => {
	try {
		const res = await getUserData(token);
		dispatch({
			type: LOAD_USER_DATA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getListaAdministradorAction = (token) => async (dispatch) => {
	try {
		const res = await getListaAdministrador(token);
		dispatch({
			type: GET_LISTA_ADMINISTRADOR,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const delAdmin = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DELETE_ADMIN,
			payload: id,
		});
		await deleteAdmin(token, id);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao deletar documento');
	}
};

export const getReenviarTokenUsuarioAction =
	(token, id) => async (dispatch) => {
		try {
			const res = await getReenviarTokenUsuario(token, id);
			dispatch({
				type: GET_REENVIAR_TOKEN_USUARIO,
				payload: res.data,
			});

			console.log(res.data);
			return false;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao reenviar');
			return true;
		}
	};

export const postPrimeiroAcesso = (user) => async (dispatch) => {
	try {
		const res = await postFirstAcess(user);
		dispatch({
			type: POST_PRIMEIRO_ACESSO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const postRecuperarSenha = (user) => async (dispatch) => {
	try {
		const res = await postResetPassword(user);
		dispatch({
			type: POST_RECUPERAR_SENHA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no recuperar senha');
			return null;
		}
	}
};

export const postSolicitarReset = (user) => async (dispatch) => {
	try {
		const res = await postSendReset(user);
		dispatch({
			type: POST_SOLICITAR_RESET,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no solicitar reset senha');
			return null;
		}
	}
};

export const postCriarAdminAction = (token, email) => async (dispatch) => {
	try {
		const res = await postCriarAdmin(token, email);
		dispatch({
			type: POST_EMAIL,
			payload: res.data,
		});
		return null;
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return true;
		}
	}
};

export const loadPerfilTaxaAction = (token, like) => async (dispatch) => {
	try {
		const res = await getPerfilTaxa(token, like);
		dispatch({
			type: LOAD_PERFIL_TAXA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPerfilTaxaIdAction = (token, id) => async (dispatch) => {
	try {
		const res = await getPerfilTaxaId(token, id);
		dispatch({
			type: LOAD_PERFIL_TAXA_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postPerfilTaxaAction =
	(
		token,
		nome,
		cash_in_payout_zoop,
		tipo_cash_in_boleto,
		cash_in_boleto,
		tipo_cash_in_ted,
		cash_in_ted,
		tipo_cash_in_pix,
		cash_in_pix,
		tipo_cash_in_p2p,
		cash_in_p2p,
		tipo_cash_out_p2p,
		cash_out_p2p,
		tipo_cash_out_ted,
		cash_out_ted,
		tipo_cash_out_pix,
		cash_out_pix
	) =>
	async (dispatch) => {
		try {
			const res = await postPerfilTaxa(
				token,
				nome,
				cash_in_payout_zoop,
				tipo_cash_in_boleto,
				cash_in_boleto,
				tipo_cash_in_ted,
				cash_in_ted,
				tipo_cash_in_pix,
				cash_in_pix,
				tipo_cash_in_p2p,
				cash_in_p2p,
				tipo_cash_out_p2p,
				cash_out_p2p,
				tipo_cash_out_ted,
				cash_out_ted,
				tipo_cash_out_pix,
				cash_out_pix
			);
			dispatch({
				type: POST_PERFIL_TAXA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error(`Erro: ${err.response.status}`);
				return true;
			}
		}
	};

export const putPerfilTaxaAction =
	(
		token,
		nome,
		cash_in_payout_zoop,
		tipo_cash_in_boleto,
		cash_in_boleto,
		tipo_cash_in_ted,
		cash_in_ted,
		tipo_cash_in_pix,
		cash_in_pix,
		tipo_cash_in_p2p,
		cash_in_p2p,
		tipo_cash_out_p2p,
		cash_out_p2p,
		tipo_cash_out_ted,
		cash_out_ted,
		tipo_cash_out_pix,
		cash_out_pix,
		id
	) =>
	async (dispatch) => {
		try {
			const res = await putPerfilTaxa(
				token,
				nome,
				cash_in_payout_zoop,
				tipo_cash_in_boleto,
				cash_in_boleto,
				tipo_cash_in_ted,
				cash_in_ted,
				tipo_cash_in_pix,
				cash_in_pix,
				tipo_cash_in_p2p,
				cash_in_p2p,
				tipo_cash_out_p2p,
				cash_out_p2p,
				tipo_cash_out_ted,
				cash_out_ted,
				tipo_cash_out_pix,
				cash_out_pix,
				id
			);
			dispatch({
				type: PUT_PERFIL_TAXA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error(`Erro: ${err.response.status}`);
				return true;
			}
		}
	};

export const delPerfilTaxa = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DELETE_PERFIL_TAXA,
			payload: id,
		});
		await deletePerfilTaxa(token, id);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error(`Erro ao deletar taxa: ${err.response.status}`);
	}
};

export const postVincularPerfilTaxaAction =
	(token, id, conta_id) => async (dispatch) => {
		try {
			const res = await postVincularPerfilTaxa(token, id, conta_id);
			dispatch({
				type: POST_VINCULAR_PERFIL_TAXA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error(`Erro: ${err.response.status}`);
				return true;
			}
		}
	};

export const postUserBloquearDesbloquearAction =
	(token, id) => async (dispatch) => {
		try {
			const res = await postUserBloquearDesbloquear(token, id);
			dispatch({
				type: POST_USER_BLOQUEAR_DESBLOQUEAR,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error(`Erro: ${err.response.status}`);
				return true;
			}
		}
	};

export const loadPermissaoGerenciar = (token, id) => async (dispatch) => {
	try {
		const res = await getPermissao(token, id);
		dispatch({
			type: LOAD_PERMISSAO_GERENCIAR,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postPermissaoAction =
	(token, id, tipoPermissao) => async (dispatch) => {
		try {
			const res = await postPermissao(token, id, tipoPermissao);
			dispatch({
				type: POST_PERMISSAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const delPermissao = (token, id, tipoPermissao) => async (dispatch) => {
	try {
		const res = await deletePermissao(token, id, tipoPermissao);
		dispatch({
			type: DEL_PERMISSAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postAuthMeAction = (token) => async (dispatch) => {
	try {
		const res = await postAuthMe(token);
		dispatch({
			type: POST_AUTH_ME,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPermissao = (token, id) => async (dispatch) => {
	try {
		const res = await getPermissao(token, id);
		dispatch({
			type: LOAD_PERMISSAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getLogsAction =
	(token, user_id, page, like, order, mostrar) => async (dispatch) => {
		try {
			const res = await getLogs(token, user_id, page, like, order, mostrar);
			dispatch({
				type: GET_LOGS,
				payload: res.data,
			});
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};
