import {
	Box,
	Button,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import { generatePath, useHistory, useParams } from 'react-router';
import {
	loadExportHistoricoTransacao,
	loadHistoricoTransacaoFilter,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../components/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../components/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import Pagination from '@material-ui/lab/Pagination';
import columns from './TransactionHistoryColumns';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';

const TransactionHistory = () => {
	const token = useAuth();
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const historico = useSelector((state) => state.historicoTransacao);
	const exportTransacao = useSelector((state) => state.exportTransacao);
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const [filters, setFilters] = useState({
		day: ' ',
		order: ' ',
		mostrar: ' ',
		status: ' ',
		like: '',
		payment_type: ' ',
		data_inicial: '',
		data_final: '',
		id: '',
		documento: '',
		vencimento_inicial: '',
		vencimento_final: '',
		pagamento_inicial: '',
		pagamento_final: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const debouncedId = useDebounce(filters.id, 800);

	useEffect(() => {
		dispatch(
			loadHistoricoTransacaoFilter(
				token,
				page,
				filters.day,
				filters.order,
				filters.mostrar,
				filters.status,
				debouncedLike,
				filters.payment_type,
				filters.data_inicial,
				filters.data_final,
				debouncedId,
				filters.documento,
				filters.vencimento_inicial,
				filters.vencimento_final,
				filters.pagamento_inicial,
				filters.pagamento_final,
				id
			)
		);
	}, [
		token,
		page,
		filters.day,
		filters.order,
		filters.mostrar,
		filters.status,
		filters.payment_type,
		filters.data_inicial,
		filters.data_final,
		filters.documento,
		filters.vencimento_inicial,
		filters.vencimento_final,
		debouncedLike,
		debouncedId,
		filters.pagamento_inicial,
		filters.pagamento_final,
		id,
	]);

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	/* const handleClickRow = (row) => {
		const path = generatePath(
			'/dashboard/gerenciar-contas/:id/detalhes-transacao',
			{
				id: row.transaction.id,
			}
		);
		history.push(path);
	}; */

	const [loading, setLoading] = useState(false);

	const handleExportarTransacao = async () => {
		setLoading(true);
		toast.warning('A exportação pode demorar um pouco, por favor aguarde...');
		const res = await dispatch(
			loadExportHistoricoTransacao(
				token,
				page,
				filters.day,
				filters.order,
				filters.mostrar,
				filters.status,
				debouncedLike,
				filters.payment_type,
				filters.data_inicial,
				filters.data_final,
				debouncedId,
				filters.documento,
				filters.vencimento_inicial,
				filters.vencimento_final,
				filters.pagamento_inicial,
				filters.pagamento_final,
				id
			)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, '', '');
		}
		setLoading(false);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			style={{ position: 'absolute' }}
		>
			<LoadingScreen isLoading={loading} />
			{token && userData === '' ? (
				<CustomBreadcrumbs
					path1="Gerenciar Listas"
					to1="goBack"
					path2="Histórico de transações"
				/>
			) : (
				<CustomBreadcrumbs path1="Histórico de transações" />
			)}
			<Box display="flex" justifyContent="">
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Histórico de Transações
				</Typography>
			</Box>
			<Box style={{ marginTop: '8px' }} display="flex">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							placeholder="Filtrar por pagador (nome, documento, e-mail...)"
							fullWidth
							label="Pagador"
							value={filters.like}
							onChange={(e) =>
								setFilters({ ...filters, like: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							placeholder="Filtrar por ID da transação"
							fullWidth
							label="ID da transação"
							value={filters.id}
							onChange={(e) =>
								setFilters({ ...filters, id: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel>Período</InputLabel>
						<Select
							fullWidth
							value={filters.day}
							onChange={(e) =>
								setFilters({ ...filters, day: e.target.value })
							}
						>
							<MenuItem value=" ">Todos</MenuItem>
							<MenuItem value={1}>Hoje</MenuItem>
							<MenuItem value={7}>Últimos 7 dias</MenuItem>
							<MenuItem value={15}>Últimos 15 dias</MenuItem>
							<MenuItem value={30}>Últimos 30 dias</MenuItem>
							<MenuItem value={60}>Últimos 60 dias</MenuItem>
							<MenuItem value={90}>Últimos 90 dias</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel>Situação</InputLabel>
						<Select
							fullWidth
							value={filters.status}
							onChange={(e) =>
								setFilters({ ...filters, status: e.target.value })
							}
						>
							<MenuItem value=" ">Todos</MenuItem>
							<MenuItem value="succeeded">Sucesso</MenuItem>
							<MenuItem value="canceled">Cancelada</MenuItem>
							<MenuItem value="failed">Falhada</MenuItem>
							<MenuItem value="charged_back">Charged Back</MenuItem>
							<MenuItem value="dispute">Em Disputa</MenuItem>
							<MenuItem value="pending">Pendente</MenuItem>
							<MenuItem value="pre_authorized">Pré-autorização</MenuItem>
							<MenuItem value="reversed">Revertida</MenuItem>
							<MenuItem value="new">Nova</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel>Método</InputLabel>
						<Select
							fullWidth
							value={filters.payment_type}
							onChange={(e) =>
								setFilters({ ...filters, payment_type: e.target.value })
							}
						>
							<MenuItem value=" ">Todos</MenuItem>
							<MenuItem value="debit">Débito</MenuItem>
							<MenuItem value="credit">Crédito</MenuItem>
							<MenuItem value="boleto">Boleto</MenuItem>
							<MenuItem value="commission">Comissão</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField
							fullWidth
							InputLabelProps={{
								shrink: true,
								pattern: 'd {4}- d {2}- d {2} ',
							}}
							type="date"
							label="Data Inicial"
							value={filters.data_inicial}
							onChange={(e) =>
								setFilters({ ...filters, data_inicial: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField
							fullWidth
							InputLabelProps={{
								shrink: true,
								pattern: 'd {4}- d {2}- d {2} ',
							}}
							type="date"
							label="Data Final"
							value={filters.data_final}
							onChange={(e) =>
								setFilters({ ...filters, data_final: e.target.value })
							}
						/>
					</Grid>
					{filters.payment_type === 'boleto' ? (
						<>
							<Grid item xs={12} sm={2}>
								<TextField
									fullWidth
									InputLabelProps={{
										shrink: true,
										pattern: 'd {4}- d {2}- d {2} ',
									}}
									type="date"
									label="Vencimento Data Inicial"
									value={filters.vencimento_inicial}
									onChange={(e) =>
										setFilters({
											...filters,
											vencimento_inicial: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									fullWidth
									InputLabelProps={{
										shrink: true,
										pattern: 'd {4}- d {2}- d {2} ',
									}}
									type="date"
									label="Vencimento Data Final"
									value={filters.vencimento_final}
									onChange={(e) =>
										setFilters({
											...filters,
											vencimento_final: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									fullWidth
									InputLabelProps={{
										shrink: true,
										pattern: 'd {4}- d {2}- d {2} ',
									}}
									type="date"
									label="Pagamento Data Inicial"
									value={filters.pagamento_inicial}
									onChange={(e) =>
										setFilters({
											...filters,
											pagamento_inicial: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									fullWidth
									InputLabelProps={{
										shrink: true,
										pattern: 'd {4}- d {2}- d {2} ',
									}}
									type="date"
									label="Pagamento Data Final"
									value={filters.pagamento_final}
									onChange={(e) =>
										setFilters({
											...filters,
											pagamento_final: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Filtrar por N° Documento"
									fullWidth
									label="N° Documento"
									value={filters.documento}
									onChange={(e) =>
										setFilters({
											...filters,
											documento: e.target.value,
										})
									}
								/>
							</Grid>
						</>
					) : null}
					<Grid item xs={12} sm={8}>
						<Box display="flex" width="100%" justifyContent="flex-end">
							<Tooltip title="Limpar Filtros">
								<IconButton
									onClick={() =>
										setFilters({
											...filters,
											id: '',
											day: ' ',
											order: ' ',
											mostrar: ' ',
											status: ' ',
											like: '',
											payment_type: ' ',
											data_inicial: '',
											data_final: '',
											documento: '',
											vencimento_final: '',
											vencimento_inicial: '',
										})
									}
								>
									<FontAwesomeIcon icon={faTrash} />
								</IconButton>
							</Tooltip>
							<Tooltip title="Exportar Excel">
								<IconButton
									variant="outlined"
									style={{ marginLeft: '6px' }}
									onClick={handleExportarTransacao}
								>
									<FontAwesomeIcon icon={faTable} color="green" />
								</IconButton>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box marginTop="16px" marginBottom="16px"></Box>
			{historico && historico.last_page ? (
				<CustomTable
					columns={columns}
					data={historico.data}
					/* handleClickRow={handleClickRow} */
				/>
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={historico.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default TransactionHistory;
