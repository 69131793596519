import {
	Box,
	Grid,
	LinearProgress,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import {
	getContasAction,
	getGraficoContaBarDashboardAction,
	getGraficoContaLineDashboardAction,
	getResumoContaDashboardAction,
	loadPermissao,
	postAuthMeAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBarChart from '../../components/CustomBarChart/CustomBarChart';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomCard from '../../components/CustomCard/CustomCard';
import CustomLineChart from '../../components/CustomLineChart/CustomLineChart';
import CustomTable from '../../components/CustomTable/CustomTable';
import ItaDash4 from '../../assets/ItaDash4.svg';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',

		height: '100%',
	},
	headerContainer: {
		display: 'flex',
		width: '100%',

		flexDirection: 'column',
	},

	contadorStyle: {
		display: 'flex',
		fontSize: '30px',
		fontFamily: 'humanist-777-bold-bt',
	},

	pageTitle: {
		color: '#c6930a',
		fontFamily: 'humanist-777-bold-bt',
	},

	cardContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
	},

	bodyContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		marginTop: '10px',
	},
}));

const columns = [
	{ headerText: 'Nome', key: 'nome' },
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Status',
		key: 'status',
		CustomValue: (value) => {
			if (value === 'pending') {
				return (
					<Typography style={{ color: 'orange' }}>Pendente</Typography>
				);
			}
			if (value === 'active') {
				return <Typography style={{ color: 'blue' }}>Ativo</Typography>;
			}
			if (value === 'enabled') {
				return <Typography style={{ color: 'blue' }}>Ativo</Typography>;
			}
			if (value === 'approved') {
				return <Typography style={{ color: 'green' }}>Aprovado</Typography>;
			}
			if (value === 'denied') {
				return <Typography style={{ color: 'red' }}>Negado</Typography>;
			}
		},
	},
	{ headerText: 'Número do Documento', key: 'numero_documento' },
	{ headerText: 'Documento', key: 'documento' },
];

const Dashboard = () => {
	const history = useHistory();
	const classes = useStyles();
	const token = useAuth();

	const [page, setPage] = useState(1);

	const dispatch = useDispatch();
	const contadores = useSelector((state) => state.contadores);

	useEffect(() => {
		dispatch(getResumoContaDashboardAction(token));
	}, []);

	const listaContas = useSelector((state) => state.contas);
	useEffect(() => {
		dispatch(
			getContasAction(
				token,
				page,

				'',
				'',
				5
			)
		);
	}, [token, page]);

	const handleVerTudo = () => {
		const path = generatePath('/dashboard/lista-de-contas');
		history.push(path);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box>
					<Typography className={classes.pageTitle}>Dashboard</Typography>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginTop: '20px',
						marginLeft: '-12px',
					}}
				>
					<CustomCard
						text="Contas pendentes"
						/* style={{ marginLeft: '0px' }} */
					>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pendente}
							</Typography>
						</Box>
					</CustomCard>

					<CustomCard text="Contas aprovadas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_aprovado}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas ativas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_ativo}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas rejeitadas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_rejeitado}
							</Typography>
						</Box>
					</CustomCard>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginLeft: '-12px',
					}}
				>
					<CustomCard text="Frequência diária">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.frequencia_quantidade_diaria}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Física">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_fisica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Jurídica">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_juridica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Total de Contas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_total}
							</Typography>
						</Box>
					</CustomCard>
				</Box>
			</Box>
			<Box className={classes.bodyContainer}>
				<Box display="flex">
					<Grid container>
						<Grid xs={8}>
							<CustomLineChart />
						</Grid>
						<Grid xs={4}>
							<CustomBarChart />
						</Grid>
					</Grid>
				</Box>
				<Box display="flex" style={{ height: '100%', marginTop: '40px' }}>
					<Grid container>
						<Grid xs={12}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',

									height: '75px',

									boxShadow: '0px 0px 5px 1px grey',
								}}
							>
								<Box
									style={{
										backgroundColor: '#35322f',
										padding: '12px',
										marginLeft: '20px',
										height: '60px',
										alignItems: 'flex-end',
									}}
								>
									<Typography
										style={{
											color: '#ffdc00',
											fontFamily: 'humanist-777-black-bt',
											marginTop: '20px',
										}}
									>
										Contas
									</Typography>
								</Box>
								<Box style={{ marginTop: '20px', marginRight: '10px' }}>
									<CustomButton
										size="large"
										color="yellow"
										onClick={handleVerTudo}
									>
										Ver Tudo
									</CustomButton>
								</Box>
							</Box>
							<Box style={{ marginBottom: '40px' }}>
								{listaContas.data && listaContas.per_page ? (
									<CustomTable
										boxShadowTop={true}
										columns={columns}
										data={listaContas.data}
									/>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export default Dashboard;
