import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
	palette: {
		background: {
			default: '#fff',
			paper: '#FFF',
		},
		primary: {
			main: '#0f1c36',
			light: '#c6d8db',
		},
		secondary: {
			main: '#35322f',
			light: '#ccc',
		},
		tertiary: {
			main: '#fff',
			light: '#fff',
		},
	},
	overrides: {
		MuiOutlinedInput: {
			root: {
				height: '45px',
				borderRadius: 27,
				'&$cssFocused $notchedOutline': {
					borderWidth: '0px',
				},

				borderWidth: '0px',
				'& :-webkit-autofill': {
					'-webkit-box-shadow': '0 0 0px 100000px white inset',
					'-webkit-padding-after': '15px',
					'-webkit-padding-before': '18px',
					'-webkit-padding-end': '15px',
					'-webkit-padding-start': '15px',
				},
				'& $notchedOutline': {
					borderWidth: 0,
				},
				'&:hover $notchedOutline': {
					borderWidth: 0,
				},
				'&$focused $notchedOutline': {
					borderWidth: 0,
				},
			},
			focused: {
				borderWidth: '0px',
			},
			notchedOutline: {
				borderWidth: '0px',
			},
			input: {
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: '#CC9B00',
					fontWeight: '600',
					fontSize: '14px',
				},
				borderRadius: '27px',
				height: '10px',
			},
		},

		MuiTextField: {
			root: {
				margin: '10px 0px 0px 0px',
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: 'translate(14px, 15px) scale(1)',
				'&$shrink': {
					transform: 'translate(14px, -20px) scale(0.8)',
					color: '#3D3935',
					fontFamily: 'Humanist-777-black-bt',
				},
			},
		},
		MuiButton: {
			contained: {
				fontFamily: 'Humanist-777-black-bt',
				fontSize: '0.8rem',
			},
		},
	},
});
